import React, { useRef, useState } from "react";
import { Col, Container, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { NotificationType, useGetVendorContactsQuery, useUpdateVendorContactMutation, useCreateVendorContactMutation, useDeleteVendorContactMutation } from '../../../../shared/types/__generated__/graphql-type';
import { ButtonStyled } from "shared/DesignSpecs/components/FormComponents";
import styled, { keyframes, css } from 'styled-components';
import { useAppState } from "shared/AppState/app-state";
import { Field, Form, Formik } from "formik";
import * as yup from 'yup';
import ConfirmMessageBox from "shared/AddEditView/ConfirmMessageBox";
import { PatternFormat } from "react-number-format";
import { getErrorObject } from "shared/utils/HelperFunctions";
import FormWrapper from "shared/components/FormWrapper";
//#endregion
//#region Schema
const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
let contactSchema = yup.object({
    contactName: yup.string()
        .trim()
        .required("service_contact__name_required")
        .matches(/^[A-z\s]*$/, "service_contact__name_required"),
    contactEmail: yup.string()
        .trim()
        .email("service_contact__email_required")
        .required("service_contact__email_required"),
    contactPhone: yup.string()
        .trim()
        .matches(phoneRegExp, "service_contact__phone_required")
        .required("service_contact__phone_required"),
    contactExt: yup.string()
        .trim()
        .optional()
        .matches(/^[0-z]*$/, "service_contact__ext_required")
});
//#endregion
// #region Styled Components
const HeaderStyled = styled(Row) `
  margin-top: 1rem;
  font-size: 2em;
  color: #5587b7;
  text-align: left;
`;
const HeaderStyled2 = styled(Row) `
  font-size: 2em;
  color: #5587b7;
  text-align: center;
  display: flex;
  justify-content: center;
`;
const ContactView = styled(Container) `
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  font-weight: 300;
  padding: ${props => props.isRegistration ? "15px 50px 50px 50px" : "4rem"};
  margin-right: 2px;
  margin-left: 2px;
  background-color: ${props => props.editingMode ? "white" : "#dce2f3"};
`;
const ContactTable = styled(Table) `
  margin-top: 2rem;
  --bs-table-bg: inherit;
`;
const EditButton = styled.i `
  &:hover {
    color: #5587b7
  }
`;
const FieldError = styled.div `
  color: red
`;
const shakeAnimation = keyframes `
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;
const AnimatedImage = styled.img `
  animation:${props => props.animate &&
    css `${shakeAnimation} 0.5s`};
  animation-iteration-count: 1;
`;
const StyledSpinner = styled(Spinner) `
  width: 4rem;
  height: 4rem;
  left: 50%;
  position: relative;
`;
const Subheader = styled(Row) `
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
`;
//#endregion
//#region Component
const ServiceContact = (props) => {
    let emptyContact = {
        id: 0,
        emailAddress: "",
        name: "",
        phoneNumber: "",
        extension: "",
        vendorId: "",
        notifyApprovals: false,
        notifyPayments: false,
        type: NotificationType.Payment
    };
    // #region State
    const [editingMode, setEditingMode] = useState(false);
    const [serviceContacts, setServiceContacts] = useState(props.isRegistration ? [emptyContact] : null);
    const [focusContactIndex, setFocusContactIndex] = useState(props.isRegistration ? 0 : null);
    const [focusContact, setFocusContact] = useState(emptyContact);
    const [pageErrors, setPageErrors] = useState([]);
    const [animateImages, setAnimateImages] = useState(false);
    // #endregion
    // #region Ref
    const deleteContactRef = useRef();
    // #endregion
    // #region Hooks
    const { data: queryData, loading: queryLoading, error: queryError, refetch: contactRefetch } = useGetVendorContactsQuery({
        variables: {
            id: props.vendorId
        },
        client: props.vendorClient,
        onError(error) {
            setPageErrors(pageErrors => getErrorObject());
        },
    });
    const [updateVendorContactMutation, { data: updateData, loading: updateLoading, error: updateError }] = useUpdateVendorContactMutation({
        client: props.vendorClient,
        onError(error, clientOptions) {
            setPageErrors(pageErrors => getErrorObject());
        },
    });
    const [createVendorContactMutation, { data: createData, loading: createLoading, error: createError }] = useCreateVendorContactMutation({
        client: props.vendorClient,
        onError(error, clientOptions) {
            setPageErrors(pageErrors => getErrorObject());
        },
    });
    const [deleteVendorContactMutation, { data: deleteData, loading: deleteLoading, error: deleteError }] = useDeleteVendorContactMutation({
        client: props.vendorClient,
        onError(error, clientOptions) {
            setPageErrors(pageErrors => getErrorObject());
        },
    });
    const [appState, dispatch] = useAppState();
    const intl = useIntl();
    // #endregion
    // #region Effects
    // #endregion
    // #region Event handlers
    const onFinishEditClicked = (resetForm) => {
        resetForm();
        setFocusContact(emptyContact);
        setFocusContactIndex(null);
        setPageErrors(pageErrors => []);
        setEditingMode(false);
    };
    const onUpdateContactsClicked = () => {
        setServiceContacts(queryData === null || queryData === void 0 ? void 0 : queryData.vendor.vendorContacts);
        setEditingMode(true);
    };
    const onEditContactClicked = (index, vendorContact) => {
        setFocusContact(vendorContact);
        setFocusContactIndex(index);
    };
    const onCancelEditContactClicked = (resetForm) => {
        if (focusContact.id == 0) {
            setServiceContacts(serviceContacts.filter(a => a.id != 0));
            resetForm();
        }
        setFocusContact(emptyContact);
        setFocusContactIndex(null);
    };
    const onAddContactClicked = () => {
        if (focusContactIndex != null) {
            setAnimateImages(true);
            setTimeout(() => {
                setAnimateImages(false);
            }, 5000);
        }
        else {
            setServiceContacts([...serviceContacts, emptyContact]);
            setFocusContactIndex(serviceContacts.length);
            setFocusContact(emptyContact);
        }
    };
    const onDeleteContactClicked = (vendorContact) => {
        setFocusContact(vendorContact);
        deleteContactRef.current.showModal();
    };
    const handleDeleteContactConfirmed = () => {
        deleteVendorContactMutation({
            variables: {
                vendorContactId: focusContact.id
            }
        }).then(() => {
            setFocusContact(emptyContact);
            contactRefetch().then((result) => {
                var _a;
                setServiceContacts((_a = result.data) === null || _a === void 0 ? void 0 : _a.vendor.vendorContacts);
            });
        });
    };
    // #endregion
    // #region International messages
    const messages = defineMessages({
        service_contact__title: {
            id: 'service_contact__title',
            description: 'Service Contacts',
            defaultMessage: 'Service Contacts'
        },
        service_contact__title2: {
            id: 'service_contact__title2',
            description: 'Step 5 of 5: Service Contacts',
            defaultMessage: 'Step 5 of 5: Service Contacts'
        },
        note: {
            id: 'note',
            description: 'Enter the Contacts that Holman should use for service-related inquiries.',
            defaultMessage: 'Enter the Contacts that Holman should use for service-related inquiries.',
        },
        service_contact__table_name: {
            id: 'service_contact__table_name',
            description: 'Service contact name header',
            defaultMessage: 'Name'
        },
        service_contact__table_phone: {
            id: 'service_contact__table_phone',
            description: 'Service contact phone header',
            defaultMessage: 'Phone Number'
        },
        service_contact__table_ext: {
            id: 'service_contact__table_ext',
            description: 'Service contact phone extension header',
            defaultMessage: 'Ext.'
        },
        service_contact__table_email: {
            id: 'service_contact__table_email',
            description: 'Service contact email header',
            defaultMessage: 'Email Address'
        },
        service_contact__finish_edit: {
            id: 'service_contact__finish_edit',
            description: 'Cancel',
            defaultMessage: 'Cancel'
        },
        service_contact__add_contact: {
            id: 'service_contact__add_contact',
            description: 'Add new contact',
            defaultMessage: 'Add new contact'
        },
        edit_configuration_caption: {
            id: 'edit_configuration_caption',
            description: 'Edit Service Contacts',
            defaultMessage: 'Edit Service Contacts'
        },
        service_contact__delete_contact_title: {
            id: 'service_contact__delete_contact_title',
            description: 'Delete Contact',
            defaultMessage: 'Delete Contact'
        },
        service_contact__delete_contact_message: {
            id: 'service_contact__delete_contact_message',
            description: 'You are about to delete a service contact. Are you sure you want to delete this contact?',
            defaultMessage: 'You are about to delete a service contact. Are you sure you want to delete this contact?'
        },
        service_contact__delete_contact_yes: {
            id: 'service_contact__delete_contact_yes',
            description: 'Yes',
            defaultMessage: 'Yes'
        },
        service_contact__delete_contact_no: {
            id: 'service_contact__delete_contact_no',
            description: 'No',
            defaultMessage: 'No'
        },
        service_contact__name_required: {
            id: 'service_contact__name_required',
            description: 'Please enter a valid name',
            defaultMessage: 'Please enter a valid name'
        },
        service_contact__email_required: {
            id: 'service_contact__email_required',
            description: 'Please enter a valid email',
            defaultMessage: 'Please enter a valid email'
        },
        service_contact__phone_required: {
            id: 'service_contact__phone_required',
            description: 'Please enter a valid phone number',
            defaultMessage: 'Please enter a valid phone number'
        },
        service_contact__ext_required: {
            id: 'service_contact__ext_required',
            description: 'Please enter a valid phone extension',
            defaultMessage: 'Please enter a valid phone extension'
        },
        service_contact__add_contact_disabled_tooltip: {
            id: 'service_contact__add_contact_disabled_tooltip',
            description: 'Cannot add new contact until selected contact is saved',
            defaultMessage: 'Cannot add new contact until selected contact is saved'
        },
        submit: {
            id: 'submit',
            description: 'Submit',
            defaultMessage: 'Submit'
        },
        service_contact__contact_required: {
            id: 'service_contact__contact_required',
            description: 'Please input a service contact in order to continue',
            defaultMessage: 'Please input a service contact in order to continue'
        },
        service_contact__name_sublabel: {
            id: 'service_contact__name_sublabel',
            description: 'First and Last name',
            defaultMessage: '(First and Last name)'
        },
    });
    // #endregion
    // #region Render helpers
    const getFieldOverlay = (fieldName, fieldValue, index, charLimit) => {
        if ((fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.length) > charLimit) {
            return (React.createElement(OverlayTrigger, { placement: "top", key: "contact_" + index + "_" + fieldName, overlay: React.createElement(Tooltip, { id: "tooltop" }, fieldValue) },
                React.createElement("div", null,
                    fieldValue.substring(0, charLimit),
                    "...")));
        }
        else {
            return (React.createElement("div", null, fieldValue));
        }
    };
    const getPhoneFormat = (phoneNumber) => {
        let normalized = ('' + phoneNumber).replace(/\D/g, '');
        let matched = normalized.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (matched) {
            return [matched[1], "(", matched[2], ") ", matched[3], "-", matched[4]].join("");
        }
    };
    // #endregion
    // #region Render
    let cssFieldSize = {
        name: 12,
        email: 18,
        phone: 15,
        ext: 6
    };
    return (React.createElement(ContactView, { editingMode: editingMode, isRegistration: props.isRegistration },
        React.createElement(FormWrapper, { id: "serviceContactError", key: "serviceContactError", formErrors: pageErrors }),
        props.isRegistration ?
            React.createElement(HeaderStyled2, null,
                React.createElement(FormattedMessage, Object.assign({}, messages.service_contact__title2)))
            :
                React.createElement(HeaderStyled, null,
                    React.createElement(FormattedMessage, Object.assign({}, messages.service_contact__title))),
        React.createElement(ConfirmMessageBox, { ref: deleteContactRef, title: messages.service_contact__delete_contact_title, message: messages.service_contact__delete_contact_message, okButtonText: messages.service_contact__delete_contact_yes, cancelButtonText: messages.service_contact__delete_contact_no, onOKClickEvent: handleDeleteContactConfirmed, onCancelClickEvent: null }),
        React.createElement(Formik, { initialValues: {
                contactName: (focusContact === null || focusContact === void 0 ? void 0 : focusContact.name) ? focusContact.name : "",
                contactEmail: (focusContact === null || focusContact === void 0 ? void 0 : focusContact.emailAddress) ? focusContact.emailAddress : "",
                contactPhone: (focusContact === null || focusContact === void 0 ? void 0 : focusContact.phoneNumber) ? focusContact.phoneNumber : "",
                contactExt: (focusContact === null || focusContact === void 0 ? void 0 : focusContact.extension) ? focusContact.extension : ""
            }, validationSchema: contactSchema, onSubmit: (values, formikProps) => {
                setPageErrors([]);
                if (focusContact.id == 0) {
                    return createVendorContactMutation({
                        variables: {
                            vendorId: props.vendorId,
                            name: values.contactName,
                            emailAddress: values.contactEmail,
                            phoneNumber: values.contactPhone,
                            extension: values.contactExt
                        }
                    }).then(() => {
                        contactRefetch().then((result) => {
                            var _a;
                            setFocusContactIndex(null);
                            setFocusContact(emptyContact);
                            setServiceContacts((_a = result.data) === null || _a === void 0 ? void 0 : _a.vendor.vendorContacts);
                            formikProps.resetForm();
                        });
                    });
                }
                else {
                    return updateVendorContactMutation({
                        variables: {
                            vendorContactId: focusContact.id,
                            name: values.contactName,
                            emailAddress: values.contactEmail,
                            phoneNumber: values.contactPhone,
                            extension: values.contactExt
                        }
                    }).then(() => {
                        contactRefetch().then((result) => {
                            var _a;
                            setFocusContactIndex(null);
                            setFocusContact(emptyContact);
                            setServiceContacts((_a = result.data) === null || _a === void 0 ? void 0 : _a.vendor.vendorContacts);
                        });
                    });
                }
            }, enableReinitialize: true }, (formikProps) => (React.createElement(Form, { style: { marginTop: "2rem", backgroundColor: props.isRegistration ? "white" : "" }, id: "contactEditForm" }, editingMode || props.isRegistration ? React.createElement(React.Fragment, null,
            React.createElement(Row, { style: { paddingLeft: "2rem", paddingTop: "1rem" } },
                React.createElement(FormattedMessage, Object.assign({}, messages.note, { values: { br: data => React.createElement("br", null), b: data => React.createElement("b", null, data), a: data => React.createElement("a", null, data) } }))),
            React.createElement(Row, { style: { paddingLeft: "2rem" } }, queryLoading || deleteLoading || updateLoading || createLoading ? React.createElement(StyledSpinner, null)
                : React.createElement(ContactTable, null,
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { style: { width: cssFieldSize.name + "rem", maxWidth: cssFieldSize.name + "rem" } },
                                React.createElement(Row, null,
                                    React.createElement(FormattedMessage, Object.assign({}, messages.service_contact__table_name))),
                                React.createElement(Subheader, null,
                                    React.createElement(FormattedMessage, Object.assign({}, messages.service_contact__name_sublabel)))),
                            React.createElement("th", { style: { width: cssFieldSize.email + "rem", maxWidth: cssFieldSize.email + "rem" } },
                                React.createElement(FormattedMessage, Object.assign({}, messages.service_contact__table_email))),
                            React.createElement("th", { style: { width: cssFieldSize.phone + "rem", maxWidth: cssFieldSize.phone + "rem" } },
                                React.createElement(FormattedMessage, Object.assign({}, messages.service_contact__table_phone))),
                            React.createElement("th", { style: { width: cssFieldSize.ext + "rem", maxWidth: cssFieldSize.ext + "rem" } },
                                React.createElement(FormattedMessage, Object.assign({}, messages.service_contact__table_ext))),
                            React.createElement("th", { style: { width: "6.5rem" } }))),
                    React.createElement("tbody", null, serviceContacts === null || serviceContacts === void 0 ? void 0 : serviceContacts.map((c, i) => (React.createElement("tr", { key: "contact_" + i }, focusContactIndex != null && focusContactIndex == i ?
                        React.createElement(React.Fragment, null,
                            React.createElement("td", null,
                                React.createElement(Field, { id: "contactName", name: "contactName", style: { width: "100%" }, maxLength: "50" }),
                                formikProps.errors.contactName && formikProps.touched.contactName &&
                                    React.createElement(FieldError, null,
                                        React.createElement(FormattedMessage, Object.assign({}, messages[formikProps.errors.contactName])))),
                            React.createElement("td", null,
                                React.createElement(Field, { id: "contactEmail", name: "contactEmail", type: "email", style: { width: "100%" }, maxLength: "50" }),
                                formikProps.errors.contactEmail && formikProps.touched.contactEmail &&
                                    React.createElement(FieldError, null,
                                        React.createElement(FormattedMessage, Object.assign({}, messages[formikProps.errors.contactEmail])))),
                            React.createElement("td", null,
                                React.createElement(PatternFormat, { format: "(###) ### ####", allowEmptyFormatting: true, mask: "_", id: "contactPhone", name: "contactPhone", onChange: formikProps.handleChange, value: formikProps.values.contactPhone, style: { width: "100%" }, maxLength: 50 }),
                                formikProps.errors.contactPhone && formikProps.touched.contactPhone &&
                                    React.createElement(FieldError, null,
                                        React.createElement(FormattedMessage, Object.assign({}, messages[formikProps.errors.contactPhone])))),
                            React.createElement("td", null,
                                React.createElement(Field, { id: "contactExt", name: "contactExt", style: { width: "100%" }, maxLength: "7" }),
                                formikProps.errors.contactExt && formikProps.touched.contactExt &&
                                    React.createElement(FieldError, null,
                                        React.createElement(FormattedMessage, Object.assign({}, messages[formikProps.errors.contactExt])))),
                            React.createElement("td", { style: { height: "calc(30px + 1rem)" } },
                                React.createElement(Row, { className: "justify-content-around" },
                                    React.createElement(ButtonStyled, { role: "button", onClick: () => { formikProps.submitForm(); }, disable: formikProps.isSubmitting, style: { width: "fit-content", padding: "0" }, id: "save_contact_btn", tabIndex: 0 },
                                        React.createElement(AnimatedImage, { animate: animateImages, src: "/assets/VMS_28button_edit_clear.png" })),
                                    React.createElement(ButtonStyled, { role: "button", onClick: () => { onCancelEditContactClicked(formikProps.resetForm); }, disable: formikProps.isSubmitting, style: { width: "fit-content", padding: "0" }, id: "cancel_edit_contact_btn", tabIndex: 0 },
                                        React.createElement(AnimatedImage, { animate: animateImages, src: "/assets/VMS_28button_kill_clear.png" })))))
                        :
                            React.createElement(React.Fragment, null,
                                React.createElement("td", null,
                                    " ",
                                    getFieldOverlay("name", c.name, i, cssFieldSize.name),
                                    " "),
                                React.createElement("td", null,
                                    " ",
                                    getFieldOverlay("email", c.emailAddress, i, cssFieldSize.email),
                                    " "),
                                React.createElement("td", null,
                                    " ",
                                    getFieldOverlay("phone", getPhoneFormat(c.phoneNumber), i, cssFieldSize.phone),
                                    " "),
                                React.createElement("td", null,
                                    " ",
                                    getFieldOverlay("ext", c.extension, i, cssFieldSize.ext),
                                    " "),
                                React.createElement("td", { style: { height: "calc(30px + 1rem)" } }, focusContactIndex == null ? React.createElement(Row, { className: "justify-content-around" },
                                    React.createElement(ButtonStyled, { role: "button", onClick: () => { onEditContactClicked(i, c); }, disable: false, style: { width: "fit-content", padding: "0" }, id: "edit_contact_btn", tabIndex: 0 },
                                        React.createElement(EditButton, { className: "fa fa-pencil-square-o fa-lg", "aria-hidden": "true", style: { fontSize: "30px" } })),
                                    React.createElement(ButtonStyled, { role: "button", onClick: () => { onDeleteContactClicked(c); }, disable: false, style: { width: "fit-content", padding: "0" }, id: "delete_contact_btn", tabIndex: 0 },
                                        React.createElement("img", { src: "/assets/VMS_28button_kill_clear.png" })))
                                    : null)))))))),
            props.isRegistration ?
                React.createElement(React.Fragment, null,
                    React.createElement(Row, { className: "justify-content-end" },
                        React.createElement(Col, { className: "justify-content-end d-flex", md: 8 },
                            React.createElement(ButtonStyled, { role: "button", onClick: () => { onAddContactClicked(); }, disable: focusContactIndex != null ? true : false, style: { width: "fit-content", marginRight: "10px", marginTop: "1rem" }, id: "add_contact_btn", tabIndex: 0 },
                                React.createElement(FormattedMessage, Object.assign({}, messages.service_contact__add_contact)),
                                " ",
                                React.createElement("img", { src: "/assets/VMS_28button_add_clear.png" })))),
                    React.createElement(Row, { className: "justify-content-end" },
                        React.createElement(Col, { className: "justify-content-end d-flex", md: 4 },
                            React.createElement(ButtonStyled, { role: "button", onClick: () => {
                                    if (focusContactIndex != null) {
                                        formikProps.validateForm().then((errors) => {
                                            if (Object.keys(errors).length === 0) {
                                                formikProps.submitForm();
                                                props.submitRegistration();
                                            }
                                            else {
                                                setPageErrors([{ message: intl.formatMessage(messages.service_contact__contact_required) }]);
                                            }
                                        });
                                    }
                                    else {
                                        if (serviceContacts.length >= 1) {
                                            props.submitRegistration();
                                        }
                                        else {
                                            setPageErrors([{ message: intl.formatMessage(messages.service_contact__contact_required) }]);
                                        }
                                    }
                                }, disable: false, style: { width: "fit-content", marginRight: "0", marginTop: "1rem" }, id: "finish_edit_btn", tabIndex: 0 },
                                React.createElement(FormattedMessage, Object.assign({}, messages.submit)),
                                " ",
                                React.createElement("img", { src: "/assets/VMS_33button_go_bblue.png" }))))) :
                React.createElement(Row, { className: "justify-content-start" },
                    React.createElement(Col, { className: "justify-content-start d-flex", md: 4 },
                        React.createElement(ButtonStyled, { role: "button", onClick: () => { onFinishEditClicked(formikProps.resetForm); }, disable: false, style: { width: "fit-content", marginRight: "0", marginTop: "1rem" }, id: "finish_edit_btn", tabIndex: 0 },
                            React.createElement(FormattedMessage, Object.assign({}, messages.service_contact__finish_edit)),
                            " ",
                            React.createElement("img", { src: "/assets/VMS_28button_delete_blue.png" }))),
                    React.createElement(Col, { className: "justify-content-end d-flex", md: 8 },
                        React.createElement(ButtonStyled, { role: "button", onClick: () => { onAddContactClicked(); }, disable: focusContactIndex != null ? true : false, style: { width: "fit-content", marginRight: "10px", marginTop: "1rem" }, id: "add_contact_btn", tabIndex: 0 },
                            React.createElement(FormattedMessage, Object.assign({}, messages.service_contact__add_contact)),
                            " ",
                            React.createElement("img", { src: "/assets/VMS_28button_add_clear.png" })))))
            : React.createElement(React.Fragment, null,
                React.createElement(Row, null, queryLoading ? React.createElement(StyledSpinner, null)
                    :
                        React.createElement(ContactTable, null,
                            React.createElement("thead", null,
                                React.createElement("tr", null,
                                    React.createElement("th", { style: { width: cssFieldSize.name + "rem", maxWidth: cssFieldSize.name + "rem" } },
                                        React.createElement(FormattedMessage, Object.assign({}, messages.service_contact__table_name))),
                                    React.createElement("th", { style: { width: cssFieldSize.email + "rem", maxWidth: cssFieldSize.email + "rem" } },
                                        React.createElement(FormattedMessage, Object.assign({}, messages.service_contact__table_email))),
                                    React.createElement("th", { style: { width: cssFieldSize.phone + "rem", maxWidth: cssFieldSize.phone + "rem" } },
                                        React.createElement(FormattedMessage, Object.assign({}, messages.service_contact__table_phone))),
                                    React.createElement("th", { style: { width: cssFieldSize.ext + "rem", maxWidth: cssFieldSize.ext + "rem" } },
                                        React.createElement(FormattedMessage, Object.assign({}, messages.service_contact__table_ext))))),
                            React.createElement("tbody", null, queryData === null || queryData === void 0 ? void 0 : queryData.vendor.vendorContacts.map((c, i) => (React.createElement("tr", { key: "contact_" + i },
                                React.createElement("td", null,
                                    " ",
                                    getFieldOverlay("name", c.name, i, cssFieldSize.name),
                                    " "),
                                React.createElement("td", null,
                                    " ",
                                    getFieldOverlay("email", c.emailAddress, i, cssFieldSize.email),
                                    " "),
                                React.createElement("td", null,
                                    " ",
                                    getFieldOverlay("phone", getPhoneFormat(c.phoneNumber), i, cssFieldSize.phone),
                                    " "),
                                React.createElement("td", null,
                                    " ",
                                    getFieldOverlay("ext", c.extension, i, cssFieldSize.ext),
                                    " "))))))),
                React.createElement(Row, null,
                    React.createElement(Row, { className: "justify-content-end" },
                        React.createElement(Col, { className: "justify-content-end d-flex", md: 8 },
                            React.createElement(ButtonStyled, { role: "button", onClick: onUpdateContactsClicked, disable: false, style: { width: "fit-content", marginRight: "0", marginTop: "1rem" }, id: "edit_contacts_btn", tabIndex: 0 },
                                React.createElement(FormattedMessage, Object.assign({}, messages.edit_configuration_caption)),
                                " ",
                                React.createElement("img", { src: "/assets/VMS_28button_edit_blue.png" })))))))))));
    // #endregion
};
// #endregion
export default (ServiceContact);
