import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { Map } from 'immutable';
import Settings from '../components/Settings';
import AccountInformation from '../components/VendorAccountInformation'
import VendorAccountViewSelector from '../selectors/VendorAccountView'
import { emptyMapIfUndefined, emptyStringIfUndefined, falseIfUndefined, emptyStringIfNull, renderError, validateEmail, zeroIfUndefined, emptyStringIfNullUndefined, validatePasswordPolicy } from 'utils/HelperFunctions';
import * as ChangeActions from '../actions/VendorAccountView';
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import VendorServices from '../components/VendorServices';
import './VendorAccountView.css';
import { Prompt } from 'react-router-dom';
import ConfirmMessageBox from 'AddEditView/ConfirmMessageBox';
import MyBusinessGrowth from '../components/MyBusinessGrowth';
import BlueBorder from '../components/BlueBorder';
import VendorDocuments from '../../VendorDocuments';
import LinkedVendorAccount from '../../../CorporateAccount/containers/LinkedVendorView';
import Gamification from '../components/Gamification';
import configuration from 'configuration';
import * as GamificationFunctions from 'utils/Gamification';
import * as helperFunctions from 'utils/HelperFunctions';
import { useAppState } from 'shared/AppState/app-state';
import ServiceContact from 'screens/YourAccount/Registration/components/ServiceContact.tsx';

const VendorAccountView = (props) => {

    const [ appState, dispatch ] = useAppState();
    const [contacts, setContacts] = useState();
    const [isRegistered, setIsRegistered] = useState();

    useEffect(() => {
        props.dispatch({ type: constants.FETCH_VENDOR_BUSINESS_GROWTH });

        if (["VW", "ED"].some(p => appState.taxIdPermssions.includes(p)))
            props.dispatch({ type: constants.FETCH_VENDOR_TAX_INFORMATION });
        
        if (["VW", "ED"].some(p => appState.servicesPermissions.includes(p)))    
            props.dispatch({ type: constants.FETCH_VENDOR_SERVICES });

        props.dispatch({ type: constants.FETCH_VENDOR_INFORMATION });

        if (["VW", "ED"].some(p => appState.bankAccountPermissions.includes(p)))         
            props.dispatch({ type: constants.FETCH_VENDOR_BANKING_INFORMATION });

        if (["VW", "ED"].some(p => appState.authorizationEmailPermissions.includes(p)))  
        {  
            props.dispatch({ type: constants.ACCOUNT_VIEW_CLEAR_AUTH_EMAIL_NOTIFICATION });
            props.dispatch({ type: constants.FETCH_ACCOUNT_VIEW_AUTH_EMAIL_NOTIFICATION });
        }

        if (appState.bankAccountPermissions.includes("ED"))         
            props.dispatch({ type: "TERMS_AND_CONDITIONS_STATIC" });

        return () => {
            props.dispatch(ChangeActions.clearPasswordSettings());
        }
    },[]);
  
    const onReturnClickHandler= (e) => {
        e.preventDefault();
        window.history.back();
    }

    const ChangePasswordClick= () => {
        props.dispatch(ChangeActions.clearPasswordSettings());
        props.dispatch(ChangeActions.resetErrorMessages());
        props.dispatch(ChangeActions.showPasswordSettings());
    }

    const ChangeContactInformationClickHandler= () => {

        props.dispatch(ChangeActions.resetErrorMessages());
        props.dispatch(ChangeActions.showContactSettings());
    }

    const isLinkedVendor= () => {
        if(props.vendorAccountType=='Sub') return true;
        else return (props.corporateVendorId!=null && props.corporateVendorId!=''  && props.currentVendorId != props.corporateVendorId);
    } 
   

    const SaveContactInfoClick= (vendorName, vendorBusinessNumber, vendorExtension, vendorFaxNumber, vendorStreetAddress1, vendorCityAddress, vendorStateAddress, vendorZipAddress, vendorEmailRecipient) => {

        

        var errorFlag = false;
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        var numericValueExpr=/^\d+$/;
        if (vendorName === '') {
            props.dispatch(ChangeActions.showVendorNameErrorMessage(true));
            errorFlag = true;
        } else {
            props.dispatch(ChangeActions.showVendorNameErrorMessage(false));
        }
        //--------------------------------------------------------------
        //--- The below code is commented out because we are currently not saving physical address from the vms to inifinium
        //--- Uncomment the code below when we have intigration in place
        //--------------------------------------------------------------
        if (props.businessAddressCountry == 'USA') {
            if (vendorStreetAddress1 === '') {
                props.dispatch(ChangeActions.showVendorStreetAddress1ErrorMessage(true));
                errorFlag = true;
            } else {
                props.dispatch(ChangeActions.showVendorStreetAddress1ErrorMessage(false));
            }
            if (vendorCityAddress === '') {
                props.dispatch(ChangeActions.showVendorCityAddressErrorMessage(true));
                errorFlag = true;
            } else {
                props.dispatch(ChangeActions.showVendorCityAddressErrorMessage(false));
            }
            if (vendorZipAddress === '') {
                props.dispatch(ChangeActions.showVendorZipAddressErrorMessage(true));
                errorFlag = true;
            } else {
                props.dispatch(ChangeActions.showVendorZipAddressErrorMessage(false));
            }
        }
        //--------------------------------------------------------------
        if (vendorBusinessNumber === '' || vendorBusinessNumber.length != 10) {
            props.dispatch(ChangeActions.showVendorBusinessNumberErrorMessage(true));
            errorFlag = true;
        } else {
            props.dispatch(ChangeActions.showVendorBusinessNumberErrorMessage(false));
        }
        if (vendorExtension !== '' && !numericValueExpr.test(vendorExtension)) {
            props.dispatch(ChangeActions.showVendorBusinessExtnErrorMessage(true));
            errorFlag = true;
        } else {
            props.dispatch(ChangeActions.showVendorBusinessExtnErrorMessage(false));
        }
        //constants.SHOW_BUSINESS_EXTN_ERROR: showBusinessExtensionError
        if (vendorFaxNumber.length > 0 && vendorFaxNumber.length != 10) {
            props.dispatch(ChangeActions.showVendorFaxNumberErrorMessage(true));
            errorFlag = true;
        } else {
            props.dispatch(ChangeActions.showVendorFaxNumberErrorMessage(false));
        }
        if(isLinkedVendor()){
            if (vendorEmailRecipient === '' || !filter.test(vendorEmailRecipient)) {
                props.dispatch(ChangeActions.showVendorEmailErrorMessage(true));
                errorFlag = true;
            } else {
                props.dispatch(ChangeActions.showVendorEmailErrorMessage(false));
            }
        }

        if (!errorFlag) {
            props.dispatch(ChangeActions.updateContactDetailsToStore(vendorName, vendorBusinessNumber, vendorExtension, vendorFaxNumber, vendorStreetAddress1, vendorCityAddress, vendorStateAddress, vendorZipAddress, vendorEmailRecipient,props.currentVendorId,props.businessAddressCountry ));
        }

    }

    const SavePasswordClick= (ariID, currentPassword, newPassword, confirmNewPassword) => {

        var errorFlag = false;
        if(props.localState==="USA" && ariID.substr(ariID.length - 2) !== "UA") ariID + "UA";
        else if (props.localState==="CAN" && ariID.substr(ariID.length - 2) !== "CC") ariID + "CC";
        else if(props.localState==="MEX" && ariID.substr(ariID.length - 2) !== "MM") ariID + "MM"; 
        /*if (ariID.substr(ariID.length - 2) !== "UA" && ariID.substr(ariID.length - 2) !== "CC") {
            ariID = props.localState === "USA" ? ariID + "UA" : ariID + "CC";
        }*/

        if (ariID == '' || ariID != props.loggedinUserId.substr(0, 10)) {
            if (ariID == '')
                props.dispatch(ChangeActions.updateAriIDErrorMessage('Mesg1'));
            else
                props.dispatch(ChangeActions.updateAriIDErrorMessage('Mesg2'));
            props.dispatch(ChangeActions.showAriIDErrorMessage(true));
            errorFlag = true;
        }
        else {
            props.dispatch(ChangeActions.showAriIDErrorMessage(false));
        }
        if (currentPassword == '') {
            props.dispatch({ type: constants.UPDATE_CURRENT_PASSWORD_ERROR_MESSAGE, errorMessage: 'empty' });
            props.dispatch(ChangeActions.showCurrentPasswordErrorMessage(true));
            errorFlag = true;
        }
        else props.dispatch(ChangeActions.showCurrentPasswordErrorMessage(false));
        
        if (newPassword == '') {
            props.dispatch(ChangeActions.updateNewPwdErrorMessage('Mesg3'));
            props.dispatch(ChangeActions.showNewPasswordErrorMessage(true));
            errorFlag = true;
        }        
        else if (newPassword != '' && newPassword == currentPassword) {
            props.dispatch(ChangeActions.updateNewPwdErrorMessage('Mesg4'));
            props.dispatch(ChangeActions.showNewPasswordErrorMessage(true));
            errorFlag = true;
        }
        else if(!validatePasswordPolicy(newPassword))
        {
            props.dispatch(ChangeActions.updateNewPwdErrorMessage('InvalidPassword'));
            props.dispatch(ChangeActions.showNewPasswordErrorMessage(true));
            errorFlag = true;
        }
        else
            props.dispatch(ChangeActions.showNewPasswordErrorMessage(false));

        if (confirmNewPassword == '' || (newPassword != '' && confirmNewPassword != newPassword)) {
            props.dispatch(ChangeActions.showConfirmNewErrorMessage(true));
            if (confirmNewPassword == '')
                props.dispatch(ChangeActions.updateConfirmNewErrorMessage('Mesg1'));
            else
                props.dispatch(ChangeActions.updateConfirmNewErrorMessage('Mesg2'));
            errorFlag = true;
        }
        else props.dispatch(ChangeActions.showConfirmNewErrorMessage(false));

        if (!errorFlag) props.dispatch(ChangeActions.savePassword(ariID, currentPassword, newPassword));//props.ariId, props.currentPassword,props.newPassword));
    }

    const PasswordTextChangedEvent= (type, value) => {

        if (type == 'ARIID' && props.passwordSettings.get('showAriIdError'))
            props.dispatch(ChangeActions.showAriIDErrorMessage(false));
        else if (type == 'CURRENT_PWD' && props.passwordSettings.get('showCurrentPwdError'))
            props.dispatch(ChangeActions.showCurrentPasswordErrorMessage(false));
        else if (type == 'NEW_PWD' && props.passwordSettings.get('showNewPwdError'))
            props.dispatch(ChangeActions.showNewPasswordErrorMessage(false));
        else if (type == 'CONFIRM_NEW_PWD' && props.passwordSettings.get('showConfirmNewPwdError'))
            props.dispatch(ChangeActions.showConfirmNewErrorMessage(false));

    }

    const PasswordCancelClickEvent= () => {
        props.dispatch(ChangeActions.clearPasswordSettings());
    }

    const ContactInformationCancelClickEventHandler= () =>  {
        props.dispatch(ChangeActions.hideContactSettings());
        props.dispatch(ChangeActions.clearVendorInfoSettings());
    }

    const AccountCancelClickEvent= () => {
        props.dispatch(ChangeActions.clearAccountSettings());
        props.dispatch(ChangeActions.clearAccountErrors())
    }

    const ServicesCancelClickEvent= () => {
        props.dispatch(ChangeActions.hideVendorServicesEdit());
        props.dispatch({ type: constants.FETCH_VENDOR_SERVICES });
    }

    const onEditAccountVerificationClickHandler= (vendorID1, vendorID2, vendorID3, vendorID4, vendorID5) => {

        const { formatMessage } = props.intl;
        let flag = false;

        const messages = defineMessages({
            error_message_recover_identification: {
                id: 'error_message_recover_identification',
                description: 'forgot to input ein or ssn',
                defaultMessage: 'Please enter tax information.'

            },
            error_message_incorrect_identification: {
                id: 'error_message_incorrect_identification',
                description: 'incorrect input of ein or ssn',
                defaultMessage: 'Invalid ID. Please enter in the following format: (XXX-XX-XXXX) or (XX-XXXXXX)'
            },
            error_message_incorrect_identification_canada: {
                id: 'error_message_incorrect_identification_canada',
                description: 'incorrect input of GST',
                defaultMessage: 'Invalid ID. Please enter in the following format: (XX-XXXXXX)'
            }
        });

        if ((vendorID1 == "" && vendorID2 == "" && vendorID3 == "")
            &&
            (vendorID4 == "" && vendorID5 == "")) {

            props.dispatch(ChangeActions.savePersonalError(formatMessage({ ...messages.error_message_recover_identification })));

            props.dispatch(ChangeActions.showPopOverVendorPersonal(true));


            flag = false;

        }
            //is ssn filled out correctly and ein empty
        else if ((vendorID1.length == 3 && vendorID2.length == 2 && vendorID3.length == 4)
            &&
            (vendorID4 == "" && vendorID5 == "")) {

            props.dispatch(ChangeActions.showPopOverVendorPersonal(false));
            props.dispatch(ChangeActions.savePersonal(vendorID1 + vendorID2 + vendorID3));
            flag = true;
        }
            //is ein filled out correctly and ssn empty
        else if ((vendorID4.length == 2 && vendorID5.length == 7)
            &&
            (vendorID1 == "" && vendorID2 == "" && vendorID3 == "")) {

            props.dispatch(ChangeActions.showPopOverVendorPersonal(false));
            props.dispatch(ChangeActions.savePersonal(vendorID4 + vendorID5));
            flag = true;
        }
            //ssn or ein is incorrectly filled out
        else {

            if (props.businessAddressCountry == "CAN")
                props.dispatch(ChangeActions.savePersonalError(formatMessage({ ...messages.error_message_incorrect_identification_canada })));
            else
                props.dispatch(ChangeActions.savePersonalError(formatMessage({ ...messages.error_message_incorrect_identification })));

            props.dispatch(ChangeActions.showPopOverVendorPersonal(true));
            flag = false;
        }


        return flag;

    }

    const validationIdHandler= (id) => {

        const { formatMessage } = props.intl;
        let flag = false;
        let ariId = '';
        const messages = defineMessages({
            error_message_contact_name: {
                id: 'error_message_contact_name',
                description: 'blank contant name',
                defaultMessage: 'Please enter valid contact name.'

            }
        });

        let regexExp = new RegExp("^[a-zA-Z-' ]+$");

        if (id == "" || !regexExp.test(id) || id.trimEnd().length < 2) {
            props.dispatch(ChangeActions.saveIdError(formatMessage({ ...messages.error_message_contact_name })));


            props.dispatch(ChangeActions.showPopOverARIId(true));
            flag = false;
        } else {
            props.dispatch(ChangeActions.showPopOverARIId(false));
            props.dispatch(ChangeActions.saveARIId(id));
            flag = true;
        }

        return flag;
    }

    const onAccountVerificationSubmitHandler= (id, vendorID1, vendorID2, vendorID3, vendorID4, vendorID5) => {

        validationIdHandler(id);
        validationTermsAgreementHandler();
        let vendorTax = "";
        let vendorTaxType = "";
        if (onEditAccountVerificationClickHandler(vendorID1, vendorID2, vendorID3, vendorID4, vendorID5) && validationIdHandler(id) && props.termsAndConditionAccept) {

            if (vendorID1 != "") {

                vendorTax = vendorID1 + '-' + vendorID2 + '-' + vendorID3;
                vendorTaxType = "SSN";
            } else {
                vendorTax = vendorID4 + '-' + vendorID5;
                vendorTaxType = "EIN";

            }
            props.dispatch(ChangeActions.verifyVendorInformation(vendorTax, vendorTaxType));

            return true;
        }
        return false;
    }

    const validationTermsAgreementHandler= () => {

        const { formatMessage } = props.intl;
        const messages = defineMessages({
            terms_and_condition_error_message: {
                id: 'terms_and_condition_error_message',
                description: 'error if submitted and terms not accepted',
                defaultMessage: 'Please agree to the terms and conditions in order to continue.'
            }
        });

        if (props.termsAndConditionAccept) {
            props.dispatch(ChangeActions.showPopOverTerms(false));
        } else {
            props.dispatch(ChangeActions.showTermsAndConditionsErrorMessage(formatMessage({ ...messages.terms_and_condition_error_message })));

            props.dispatch(ChangeActions.showPopOverTerms(true));
        }

    }

    const onAccountVerificationShowHandler= () => {

        props.dispatch(ChangeActions.showAccountVerification());
    }

    const onPaymentEmailVerificationShowHandler= () => {
        props.dispatch(ChangeActions.showPaymentEmailVerification());        
    }

    const agreedToTermsAndConditionsHandler= () => {
        onBlurChange();

        if (!props.termsAndConditionAccept) {
            props.dispatch(ChangeActions.agreedToTermsAndConditions(true));
        } else {
            props.dispatch(ChangeActions.agreedToTermsAndConditions(false));
        }
    }

    const checkingAccountHandler= (checking1, checking2) => {

        const { formatMessage } = props.intl;
        let flag = false;
        const messages = defineMessages({
            checking_account_match_error_message: {
                id: 'checking_account_duplicate_error_message',
                description: 'error message when checking account numbers do not match',
                defaultMessage: 'Both account numbers must be identical. Please re-enter_checking account number.'
            },
            checking_account_empty_error_message: {
                id: 'checking_account_empty_error_message',
                description: 'error message when checking account number is empty',
                defaultMessage: 'Please enter checking account number.'
            }

        });

        if (checking1 != checking2) {
            props.dispatch(ChangeActions.saveChecking2Error(formatMessage({ ...messages.checking_account_match_error_message })));


            props.dispatch(ChangeActions.showPopOverChecking2(true));
            flag = false;

        }
        else if (checking1 === "" || checking2 === "") {

            if (checking1 === "" && checking2 === "") {

                props.dispatch(ChangeActions.saveChecking1Error(formatMessage({ ...messages.checking_account_empty_error_message })));
                props.dispatch(ChangeActions.saveChecking2Error(formatMessage({ ...messages.checking_account_empty_error_message })));


                props.dispatch(ChangeActions.showPopOverChecking2(true));
                props.dispatch(ChangeActions.showPopOverChecking1(true));

            }
            else if (checking1 === "") {

                props.dispatch(ChangeActions.saveChecking1Error(formatMessage({ ...messages.checking_account_empty_error_message })));


                props.dispatch(ChangeActions.showPopOverChecking1(true));

            }

            else {
                props.dispatch(ChangeActions.saveChecking2Error(formatMessage({ ...messages.checking_account_empty_error_message })));


                props.dispatch(ChangeActions.showPopOverChecking2(true));
            }
            flag = false;
        }
        else {

            props.dispatch(ChangeActions.showPopOverChecking1(false));
            props.dispatch(ChangeActions.showPopOverChecking2(false));
            props.dispatch(ChangeActions.saveChecking1(checking1));
            props.dispatch(ChangeActions.saveChecking2(checking2));
            flag = true;
        }

        return flag;
    }

    const routingHandler= (routing) => {
        const { formatMessage } = props.intl;
        let flag = false;
        const messages = defineMessages({
            routing_empty_error_message: {
                id: 'routing_empty_error_message',
                description: 'error message when routing number is empty',
                defaultMessage: 'Please Enter a valid routing and transit number.'
            },
            invalid_routing_error_message: {
                id: 'invalid_routing_error_message',
                description: 'error message when routing number is invalid',
                defaultMessage: 'The first digit should start with 0,1,2 or 3. Invalid Routing number.'
            }

        });

        if (routing.charAt(0) != "0" && routing.charAt(0) != "1" && routing.charAt(0) != "2" && routing.charAt(0) != "3") {

            props.dispatch(ChangeActions.saveRoutingNumberError(formatMessage({ ...messages.invalid_routing_error_message })));


            props.dispatch(ChangeActions.showPopOverRoutingNumber(true));
            flag = false;

        }
        else if (routing === "" || (props.businessAddressCountry == 'CAN' ? routing.length < 8 : routing.length < 9)) {
            props.dispatch(ChangeActions.saveRoutingNumberError(formatMessage({ ...messages.routing_empty_error_message })));

            props.dispatch(ChangeActions.showPopOverRoutingNumber(true));
            flag = false;
        } else {
            props.dispatch(ChangeActions.showPopOverRoutingNumber(false));
            props.dispatch(ChangeActions.saveRoutingNumber(routing));

            flag = true;
        }

        return flag;
    }


    const onSaveBankingAccountClickHandler= (routing, checking1, checking2) => {


        if (checkingAccountHandler(checking1, checking2) && routingHandler(routing)) {

            props.dispatch(ChangeActions.updateBankingAccountDetailsToStore(routing, checking1, checking2));

            return true;

        } else {

            checkingAccountHandler(checking1, checking2);
            routingHandler(routing);
        }

    }

    const onSavePaymentEmailHandler= (paymentEmail) => {
        
        props.dispatch(ChangeActions.updatePaymentEmailAddressToStore(paymentEmail))
    }

    const ChangeVendorServicesClickHandler= () => {

        props.dispatch(ChangeActions.showVendorServicesEdit());

    }

    const vendorServicesSelectedHandler= (selectedValue, serviceId) => {
        onBlurChange();

        if (selectedValue) {
            props.dispatch(ChangeActions.saveVendorServiceSelection(false, serviceId));
        } else {
            props.dispatch(ChangeActions.saveVendorServiceSelection(true, serviceId));
        }


    }

    const vendorMinoritySelectedHandler= (serviceId) => {

        onBlurChange();
        props.vendorMinority.map(service => {

            props.dispatch(ChangeActions.saveVendorMinoritySelection(false, service.get("id")));

        });
        props.dispatch(ChangeActions.saveVendorMinoritySelection(true, serviceId));



    }

    const onSaveVendorServicesClickHandler=() => {


        props.dispatch({ type: constants.SAVE_VENDOR_SERVICES });




    }

    const onBlurChange= () => {

        props.dispatch({ type: constants.VENDOR_ACCOUNT_IS_DIRTY });
    }
    const isFieldEmpty= (value) => {
        return (emptyStringIfUndefined(value) == '' || value.length == undefined || value.length == 0);
    }
    const validateFormAuthEmailConfig= () => {
        const errorMessages = defineMessages({
            atleast_one_email_required: {
                id: 'atleast_one_email_required',
                description: 'Please enter atleast one email',
                defaultMessage: 'Please enter atleast one email'
            },
            name_email_required: {
                id: 'name_email_required',
                description: 'Both Name and Email are required',
                defaultMessage: 'Both Name and Email are required'
            },
            invalid_email_format: {
                id: 'invalid_email_format',
                description: 'Email format is invalid',
                defaultMessage: 'Email format is invalid'
            },
        })

        let isFormValid = true;
        let validationErrs;
        let contacts = props.authorizationEmailConfig;
        if (contacts != undefined && contacts.length > 0) {

            for (var index = 0; index < contacts.length; index++) {

                //required 
                if ((isFieldEmpty(contacts[index].name) && !isFieldEmpty(contacts[index].email))
                    || (!isFieldEmpty(contacts[index].name) && isFieldEmpty(contacts[index].email))) {
                    isFormValid = false;
                    validationErrs = {
                        name: index,
                        value: errorMessages.name_email_required
                    }
                    props.dispatch({ type: constants.ACCOUNT_VIEW_UPDATE_EMAIL_CONFIG_VALIDATION_ERRS, validationErrs })
                }

                //email format
                if (!isFieldEmpty(contacts[index].email) && !validateEmail(contacts[index].email)) {
                    isFormValid = false;
                    validationErrs = {
                        name: index,
                        value: errorMessages.invalid_email_format
                    }
                    props.dispatch({ type: constants.ACCOUNT_VIEW_UPDATE_EMAIL_CONFIG_VALIDATION_ERRS, validationErrs })
                }
            }
        }

        return isFormValid;
    }

    const onSubmitHandler= (step) => {
        if (validateFormAuthEmailConfig()) {
            props.dispatch({ type: constants.ACCOUNT_VIEW_SAVE_AND_FETCH_EMAIL_AUTH_NOTIFICATION });

        }
    }
    const addContactRowHandler= () => {
        let contacts = props.authorizationEmailConfig;
        let updatedContacts = contacts.concat({ id: props.maxContactIdForAdd, name: '', email: '', elementState: constants.ELEMENT_STATE.ADD });
        props.dispatch({ type: constants.ACCOUNT_VIEW_UPDATE_NOTIFICATION_CONTACT, updatedContacts })
    }
    const updateContactHandler= (inputName, index, event) => {
        if (event != undefined) {
            let updatedContacts = props.authorizationEmailConfig;
            updatedContacts[index][inputName] = event.target.value;
            const firstContactIdForAdd = props.firstContactIdForAdd;
            if (updatedContacts[index].id == 0 || updatedContacts[index].id >= firstContactIdForAdd)
                updatedContacts[index].elementState = constants.ELEMENT_STATE.ADD;
            else
                updatedContacts[index].elementState = constants.ELEMENT_STATE.UPDATE;

            props.dispatch({ type: constants.ACCOUNT_VIEW_UPDATE_NOTIFICATION_CONTACT, updatedContacts })
        }
    }
    const removeContactHandler= (index, event) => {
        let updatedContacts = props.authorizationEmailConfig;
        updatedContacts[index].elementState = constants.ELEMENT_STATE.DELETE;
        props.dispatch({ type: constants.ACCOUNT_VIEW_REMOVE_NOTIFICATION_CONTACT, updatedContacts })
    }
    const updateEmailConfigRegistrationHandler= (isRegistered) => {
        props.dispatch({ type: constants.ACCOUNT_VIEW_UPDATE_EMAIL_CONFIG_REGISTRATION, isRegistered })
    }
    const removeValidationErrForAuthEmailConfigHandler= (fieldName) => {
        props.dispatch({ type: constants.ACCOUNT_VIEW_REMOVE_EMAIL_CONFIG_VALIDATION_ERRS, fieldName })
    }
    const getAuthNotificationHeader= () => {
        const messages = defineMessages({
            auth_notification_title: {
                id: 'auth_notification_title',
                description: 'Authorization Email Configuration',
                defaultMessage: 'Authorization Email Configuration'
            }
        });

        return <div className='vendor-account-view__auth-notification-header'>
            <FormattedMessage {...messages.auth_notification_title } /></div>
            }
    const onEditConfigurationHandler= () => {
        let contacts = props.authorizationEmailConfig;
        let isRegistered = props.authorizationEmailConfigIsRegistered;
        setContacts(contacts);
        setIsRegistered(isRegistered);
        props.dispatch({ type: constants.ACCOUNT_VIEW_EMAIL_CONFIG_FORM_MODE, formMode: constants.EDIT })
    }

    const onCancelHandler= () => {
        //hack: convert contacts to JSON while saving back to the state to make sure it doesnt break the UI. 
        const configurationInitialState = {
            contacts: contacts,
            isRegistered: isRegistered,
        }
        props.dispatch({ type: constants.ACCOUNT_VIEW_RESET_AUTH_EMAIL_NOTIFICATION, configurationInitialState: configurationInitialState });
    }
   

        // Do not show gamification data to some vendors
        const excludeGamificationData = GamificationFunctions.ExcludeGamificationData(props.currentVendorId, props.serviceClassification);

        if (props.isFetchingData) {
            return <Spinner />;
        } else if (props.isFetchingGrowthData) {
            return <Spinner />;
        }

        const messages = defineMessages({
            return: {
                id: 'return',
                description: 'Return button',
                defaultMessage: 'Return'
            },
            vendor_account_confirmation_no: {
                id: 'vendor_account_confirmation_no',
                description: 'No',
                defaultMessage: 'No'
            },
            vendor_account_confirmation_yes: {
                id: 'vendor_account_confirmation_yes',
                description: 'Yes',
                defaultMessage: 'Yes'
            },
            vendor_account_confirmation_title: {
                id: 'vendor_account_confirmation_title',
                description: 'Vendor Account',
                defaultMessage: 'Vendor Account'
            },
            vendor_account_confirmation_message: {
                id: 'vendor_account_confirmation_message',
                description: 'Vendor Account message',
                defaultMessage: 'This page has unsaved changes. Please click CANCEL to stay on this screen.'
            },
        });

        const gamificationDataServerURL = `${configuration.serviceURL}v1/vendors/${props.currentVendorId}/performance?token=${props.token}`;

        return <div>
            <Prompt when={props.isDirty} message="This page has unsaved changes. Please click CANCEL to stay on this screen." />
            {
                <ConfirmMessageBox
                    title={messages.vendor_account_confirmation_title}
                    message={messages.vendor_account_confirmation_message}
                    okButtonText={messages.vendor_account_confirmation_yes}
                    cancelButtonText={messages.vendor_account_confirmation_no}
                    onOKClickEvent={onReturnClickHandler.bind(this)}
                    onCancelClickEvent={null} />
            }

            {!excludeGamificationData && props.serviceClassification !== "CARWASH" && props.selectedCountry!=="MEX"?
                <BlueBorder>
                    <Gamification serverURL={gamificationDataServerURL}/>
                </BlueBorder>
                : undefined
            }

			{props.serviceClassification !== "CARWASH" && props.selectedCountry!=="MEX" ?            

            <MyBusinessGrowth
                vehicleServiceDue={props.vehicleServiceDue}
                vehicleTotalCount={props.vehicleTotalCount}
                additionalVehicleServiceDue={props.additionalVehicleServiceDue}
            />
                : undefined
            }

            {['VW', 'ED'].some(p => appState.accountSettingPermissions.includes(p)) &&
            <Settings
                isLinkedVendor={isLinkedVendor()}                
                isSSOUser={props.isSSOUser}
                editModeContact={props.editMode}
                showVendorError={props.showVendorError}
                passwordSettings={props.passwordSettings}
                loggedinUserId={props.loggedinUserId}
                onChangeContactClick={ChangeContactInformationClickHandler.bind(this)}
                vendorDetails={props.vendorDetails}
                onCancelContactClick={ContactInformationCancelClickEventHandler.bind(this)}
                saveOnClickHandler={SaveContactInfoClick.bind(this)}
                onTextChangeHandler={PasswordTextChangedEvent.bind(this)}
                editMode={props.passwordSettings.get('isEditMode')}
                onCancelClickHandler={PasswordCancelClickEvent.bind(this)}
                onSavePasswordClickHandler={SavePasswordClick.bind(this)}
                onChangePasswordClickHandler={ChangePasswordClick.bind(this)}
                businessName={props.businessName}
                businessAddress={props.businessAddress}
                businessState={props.businessState}
                businessCity={props.businessCity}
                businessZip={props.businessZip}
                businessContact={props.businessContact}
                businessPhone={props.businessPhone}
                businessExtension={props.businessExtension}
                businessFax={props.businessFax}
                businessEmail={props.businessEmail}
                onBlurChange={onBlurChange.bind(this)}
                contactNamePayment={props.contactNamePayment}
                contactEmailPayment={props.contactEmailPayment}
                isSubmittingData={props.isSubmittingContactData}
                tabIndexStartFrom={100}
                country={props.businessAddressCountry}
                vendorContactUpdateErrorMessage={props.vendorContactUpdateErrorMessage}
                vendorAccountType={props.vendorAccountType}
            />
            }

            {(props.vendorAccountType === "Sub" || (props.vendorAccountType === "Master" && props.currentVendorId !== "" && props.corporateVendorId !== "")) ||
              props.selectedCountry=="MEX" ?
                <hr className="vendor-account-view__hr" />
                :
                <AccountInformation
                    isLinkedVendor={isLinkedVendor()}
                    editModeValidation={props.editModeValidation} 
                    paymentEmailEditModeValidation={props.paymentEmailEditModeValidation}                   
                    onChangeAccountVerifyClick={onAccountVerificationShowHandler.bind(this)}
                    onChangePaymentEmailVerifyClick={onPaymentEmailVerificationShowHandler.bind(this)}
                    onCancelAccountClick={AccountCancelClickEvent.bind(this)}
                    onEditAccountClick={onAccountVerificationSubmitHandler.bind(this)}
                    editModeAccount={props.editModeAccount}
                    editModePaymentEmail={props.editModePaymentEmail}
                    isFetchingData={props.isFetchingData}
                    popOverDisplayShowARIId={props.popOverDisplayShowARIId}
                    popOverDisplayShowPersonal={props.popOverDisplayShowPersonal}
                    accountApproved={props.accountApproved}
                    accountIdError={props.accountIdError}
                    accountPersonalError={props.accountPersonalError}
                    accountErrorMessage={props.accountErrorMessage}
                    termsAndConditionAccept={props.termsAndConditionAccept}
                    agreedToTermsAndConditions={agreedToTermsAndConditionsHandler.bind(this)}
                    popOverDisplayShowChecking1={props.popOverDisplayShowChecking1}
                    popOverDisplayShowChecking2={props.popOverDisplayShowChecking2}
                    popOverDisplayShowRoutingNumber={props.popOverDisplayShowRoutingNumber}
                    checking1Error={props.checking1Error}
                    checking2Error={props.checking2Error}
                    routingNumberError={props.routingNumberError}
                    onSaveBankingAccount={onSaveBankingAccountClickHandler.bind(this)}
                    onSavePaymentEmail={onSavePaymentEmailHandler.bind(this)}
                    accountSubmissionSuccess={props.accountSubmissionSuccess}                    
                    popOverDisplayShowTerms={props.popOverDisplayShowTerms}
                    termsError={props.termsError}
                    onBlurChange={onBlurChange.bind(this)}
                    vendorCheckingAccount={props.vendorCheckingAccount}
                    taxId={props.taxId}
                    taxClassification={props.taxClassification}
                    country={props.businessAddressCountry}
                    tabIndexStartFrom={200}
                    isDealerDraft={props.isDealerDraft}
                    accountCountry={props.accountCountry}
                    termsAndConditions={props.termsAndConditions}
                    toggleAcceptedFlag={props.toggleAcceptedFlag}
                    isFetchingTermsAndConditions={props.isFetchingTermsAndConditions}
                    paymentType={props.paymentType}
                    selectedCountry={props.selectedCountry}
                    isWorkCompletionFeatureEnabled={props.isWorkCompletionFeatureEnabled}
                    vendorAccountType={props.vendorAccountType}
                    paymentEmail={props.contactEmailPayment}
                    isSSOUser={props.isSSOUser}                    
                        />
            }

            {appState.personaUserId != undefined ? false : 
            props.vendorAccountType === "Individual" || props.selectedCountry=="MEX" ? false :

                <div>
                    <LinkedVendorAccount />
                    <hr className="vendor-account-view__hr" />
                </div>
            
        }
			{ props.selectedCountry!=="MEX" && ["VW","ED"].some(p => appState.documentPermisions.includes(p)) ?
            <BlueBorder>
                <VendorDocuments />
            </BlueBorder>
            :false
            }

            {props.serviceClassification !== "CARWASH" ?
                <div>
            <div className=''>
                {appState.authorizationEmailPermissions.includes("VW") && 
                <ServiceContact vendorId={props.currentVendorId} vendorClient={props.vendorClient} isRegistration={false}/>}
            </div>
            {["VW","ED"].some(p => appState.servicesPermissions.includes(p)) && 
            <VendorServices
                vendorServices={props.vendorServices}
                vendorMinority={props.vendorMinority}
                vendorServicesClick={ChangeVendorServicesClickHandler.bind(this)}
                isEditModeServices={props.isEditModeServices}
                minoritySelected={props.minoritySelected}
                vendorServicesSelected={vendorServicesSelectedHandler.bind(this)}
                servicesCancel={ServicesCancelClickEvent.bind(this)}
                vendorMinoritySelected={vendorMinoritySelectedHandler.bind(this)}
                onSaveVendorServices={onSaveVendorServicesClickHandler.bind(this)}
                onBlurChange={onBlurChange.bind(this)}
                tabIndexStartFrom={400}
                showVendorMinority={props.showVendorMinority}
                country={props.selectedCountry}
            />}
                </div>
                : undefined
            }
            <div className="vendor-account-view__return"
                role="button"
                onClick={onReturnClickHandler.bind(this)}>
                <img src="/assets/VMS_33button_back_blue.png" />
                {' '}
                <FormattedMessage {...messages.return} />
            </div>
        </div>;
    }


function select(state) {
    return {        
        isFetchingData: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'isFetchingData'])),
        isFetchingGrowthData: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'isFetchingGrowthData'])),
        editModeValidation: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'isEditModeValidation'])),
        paymentEmailEditModeValidation: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'isPaymentEmailEditModeValidation'])),
        editMode: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'isEditMode'])),
        showVendorError: emptyMapIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'showError'])),
        businessName: emptyStringIfNull(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'businessName'])),
        businessAddress: emptyStringIfNull(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'businessAddress', 'address1'])),
        businessCity: emptyStringIfNull(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'businessAddress', 'city'])),
        businessState: emptyStringIfNull(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'businessAddress', 'state'])),
        businessZip: emptyStringIfNull(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'businessAddress', 'zipCode'])),
        businessContact: emptyStringIfNull(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'businessContact'])),
        businessPhone: emptyStringIfNullUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'businessPhone'])),
        businessExtension: emptyStringIfNull(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'businessExtension'])),
        businessFax: emptyStringIfNullUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'businessFax'])),
        businessEmail: emptyStringIfNull(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'businessEmail'])),
        businessAddressCountry: emptyStringIfNull(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'businessAddress', 'country'])),
        editModeAccount: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'isEditMode'])),
        editModePaymentEmail: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'isPaymentEmailEditMode'])),
        popOverDisplayShowARIId: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'popOver', 'isPopOverDisplayedShowARIId'])),
        popOverDisplayShowPersonal: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'popOver', 'isPopOverDisplayedShowPersonal'])),
        accountApproved: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'isApprovedData'])),
        accountIdError: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'errorMessage', 'informationIdError'])),
        accountPersonalError: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'errorMessage', 'informationPersonalError'])),
        accountErrorMessage: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'errorMessage'])),
        country: emptyStringIfUndefined(state.appState.getIn(['uiData', 'shared', 'selectedLocale'])),
        termsAndConditionAccept: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'agreedToTerms'])),
        popOverDisplayShowChecking1: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'popOver', 'isPopOverDisplayedShowChecking1'])),
        popOverDisplayShowChecking2: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'popOver', 'isPopOverDisplayedShowChecking2'])),
        popOverDisplayShowRoutingNumber: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'popOver', 'isPopOverDisplayedShowRoutingNumber'])),
        checking1Error: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'errorMessage', 'checking1Error'])),
        checking2Error: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'errorMessage', 'checking2Error'])),
        routingNumberError: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'errorMessage', 'routingNumberError'])),        
        accountSubmissionSuccess: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'submissionApproved'])),
        popOverDisplayShowTerms: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'popOver', 'isPopOverDisplayedShowTerms'])),
        termsError: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'errorMessage', 'showTermsAndConditionError'])),
        isEditModeServices: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'vendorProfile', 'isEditMode'])),
        isDirty: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'isDirty'])),
        taxId: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'tax', 'taxId'])),
        taxClassification: emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'tax', 'taxClassification'])),
        authorizationEmailConfigValidationErrs: state.appState.getIn(['uiData', 'vendorAccount', 'authorizationEmailConfigValidationErrs', 'contacts']),
        isSubmittingAuthEmailConfigInfo: state.appState.getIn(['uiData', 'vendorAccount', 'isSubmittingAuthEmailConfigInfo']),
        formMode: state.appState.getIn(['uiData', 'vendorAccount', 'authorizationEmailConfig', 'formMode']),
        isFetchingAuthEmailConfigInfo: state.appState.getIn(['uiData', 'vendorAccount', 'authorizationEmailConfig', 'isFetchingAuthEmailConfigInfo']),
        authorizationEmailConfigServerErr: state.appState.getIn(['uiData', 'vendorAccount', 'authorizationEmailConfig', 'authorizationEmailConfigErrormessage']),
        vehicleServiceDue: zeroIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'myGrowth', 'statistics','vehiclesStatistics' ,'vehicleActiveServiceDueCount'])),
        vehicleTotalCount: zeroIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'myGrowth', 'statistics','vehiclesStatistics' ,'vehicleTotalCount'])),
        additionalVehicleServiceDue: zeroIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'myGrowth', 'statistics','vehiclesStatistics', 'vehicleInActiveServiceDueCount'])),
        vendorAccountType: state.appState.getIn(['serverData', 'shared', 'vendorAccountType']),
        firstContactIdForAdd: state.appState.getIn(['uiData', 'vendorAccount', 'authorizationEmailConfig', 'firstContactIdForAdd']),
        corporateVendorId: emptyStringIfNull(state.appState.getIn(['serverData', 'shared', 'corporateId'])),
        currentVendorId: emptyStringIfNull(state.appState.getIn(['serverData', 'shared', 'vendorId'])),
        isSubmittingContactData: state.appState.getIn(['uiData', 'vendorAccount', 'isSubmittingContactData']),
        localState: state.appState.getIn(['uiData', 'shared', 'selectedCountry']),
        serviceClassification:state.appState.getIn(['serverData','shared','serviceClassification']),
        vendorContactUpdateErrorMessage:state.appState.getIn(['uiData', 'vendorAccount','settingsView', 'contactSettings', 'ariIdErrorMessage']),
        token:state.appState.getIn(['serverData','shared','token']),
        isDealerDraft:state.appState.getIn(['serverData','shared','isDealerDraft']),
        accountCountry: state.appState.getIn(['uiData', 'shared', 'selectedCountry']),
        termsAndConditions:state.appState.getIn(['serverData', 'shared', 'vendorTermsAndConditions']) || null,
        toggleAcceptedFlag:state.appState.getIn(['uiData', 'shared', 'vendorTermsAndConditions','toggleAcceptedFlag']) || false,
        isFetchingTermsAndConditions:state.appState.getIn(['uiData', 'shared', 'vendorTermsAndConditions','isFetchingTermsAndCondition']) || false,
        paymentType:state.appState.getIn(['serverData', 'shared', 'paymentType']) || null,
        selectedCountry:state.appState.getIn(['uiData', 'shared', 'selectedCountry']) || null,
        isWorkCompletionFeatureEnabled: state.appState.getIn(['uiData', 'shared', 'features','workCompletion']),
        isSSOUser: state.appState.getIn(['serverData', 'shared', 'isSSOUser'])==undefined?false:state.appState.getIn(['serverData', 'shared', 'isSSOUser']),
        ...VendorAccountViewSelector(state)
    };
}


export default connect(select)(injectIntl((VendorAccountView)));