import { call, put, select, take, cancel, fork,all } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import configuration from 'configuration';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import {driverValidationComplaintsSagas} from 'PO/DriverValidationComplaints';


export function* fetchDataForOdometerScreen(action) {
  try {
    const state = yield select();

    //clear out any error messages
    yield put({ type: constants.CLEAR_ERROR_MESSAGE });
    // Inform application that data request has started
    yield put({ type: constants.FETCH_DATA_FOR_ODOMETER_SCREEN_REQUESTED });
    // Get recent repair history of vehicle
    const recentServicesData = yield call(Api.fetchRepairHistory, action.data.vehicleId, 0, configuration.recentServicePageSize, helperFunctions.getToken(state));
   
    if (action.data.shouldLoadLaborRate)
    {
    //get vendor Vehicle parameters
      const vendorVehicleParameters = yield call(Api.fetchVendorVehicleParameters, helperFunctions.getVendorId(state), action.data.vehicleId, helperFunctions.getToken(state));
      yield put({type: constants.SET_VENDOR_VEHICLE_PARAMETERS, vendorVehicleParameters});
    }

    yield put({ type: constants.SAVE_RECENT_SERVICE_DATA, recentServicesData });
    yield put({ type: constants.FETCH_DATA_FOR_ODOMETER_SCREEN_RESOLVED });

  }
  catch (e) {
    yield put({ type: constants.FETCH_DATA_FOR_ODOMETER_SCREEN_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e)});
    yield put({ type: constants.SET_ERROR_MESSAGE,  errorMessage: helperFunctions.getGenericErrorObject(e)});
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.FETCH_DATA_FOR_ODOMETER_SCREEN_REQUESTED, errorObject: e });
  }
}

export function* createPOClicked(context, action) {
  try {
    let state = yield select();
    const token = helperFunctions.getToken(state);

    //clear out any error messages
    yield put({ type: constants.CLEAR_ERROR_MESSAGE });

    //If there is already a PO being created, then do not make a call for new po; else create a new po

    let poNumber;
    if (state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder']) == undefined || state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']) == undefined) {
      // Inform application that PO is being created
      yield put({ type: constants.CREATE_PO_REQUESTED });
      poNumber = yield call(Api.createPO, state, token);
      yield put({ type: constants.CREATE_PO_RESOLVED, poNumber });
    }
   
    poNumber = poNumber ? poNumber : state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']);
    yield call(action.assignVendorContactToOrder, {
        variables: {
          orderId: poNumber,
          vendorContactId: action.vendorContactId
        }
      });

    // fetch available complaints
    const vehicleId = state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'id']);
    const vendorId = state.appState.getIn(['serverData','shared','vendorId']);
    
    const [availableVehicleComplaints] = yield all ([
            call(Api.fetchAvailableVehicleComplaints, vehicleId, vendorId, token)
        ]);          
    if (availableVehicleComplaints) {
      yield put({ type: constants.ADD_EDIT_VIEW_DELETE_COMPLAINTS });
        yield call(context.history.push,`/po/driverValidationComplaints/${action.vehicleId}`);
    } else {
        yield call(context.history.push,`/po/complaint/${action.vehicleId}`);
    }
  }
  catch (e) {
    yield put({ type: constants.CREATE_PO_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e)});
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e)});
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.CREATE_PO_REQUESTED, errorObject: e });
  }
}