import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export var ApplyPolicy;
(function (ApplyPolicy) {
    ApplyPolicy["AfterResolver"] = "AFTER_RESOLVER";
    ApplyPolicy["BeforeResolver"] = "BEFORE_RESOLVER";
    ApplyPolicy["Validation"] = "VALIDATION";
})(ApplyPolicy || (ApplyPolicy = {}));
export var EstimateStatus;
(function (EstimateStatus) {
    EstimateStatus["Approved"] = "APPROVED";
    EstimateStatus["DeclinedByShop"] = "DECLINED_BY_SHOP";
    EstimateStatus["None"] = "NONE";
    EstimateStatus["WaitingForEstimateAndImages"] = "WAITING_FOR_ESTIMATE_AND_IMAGES";
    EstimateStatus["WaitingForHolmanApproval"] = "WAITING_FOR_HOLMAN_APPROVAL";
    EstimateStatus["WaitingForRepairEta"] = "WAITING_FOR_REPAIR_ETA";
})(EstimateStatus || (EstimateStatus = {}));
export var NotificationType;
(function (NotificationType) {
    NotificationType["Approval"] = "APPROVAL";
    NotificationType["Payment"] = "PAYMENT";
})(NotificationType || (NotificationType = {}));
export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["None"] = "NONE";
    PaymentStatus["Paid"] = "PAID";
    PaymentStatus["PaymentInProgress"] = "PAYMENT_IN_PROGRESS";
    PaymentStatus["PaymentNotRequested"] = "PAYMENT_NOT_REQUESTED";
    PaymentStatus["WorkCompleted"] = "WORK_COMPLETED";
})(PaymentStatus || (PaymentStatus = {}));
export var RepairEstimateType;
(function (RepairEstimateType) {
    RepairEstimateType["Primary"] = "PRIMARY";
    RepairEstimateType["Secondary"] = "SECONDARY";
})(RepairEstimateType || (RepairEstimateType = {}));
export const CreateEstimateLinesDocument = gql `
    mutation CreateEstimateLines($lines: [RepairEstimateLineItemInput!]!, $repairEstimateId: Long!) {
  createEstimateLines(input: {lines: $lines, repairEstimateId: $repairEstimateId}) {
    successPayload {
      success
    }
  }
}
    `;
/**
 * __useCreateEstimateLinesMutation__
 *
 * To run a mutation, you first call `useCreateEstimateLinesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEstimateLinesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEstimateLinesMutation, { data, loading, error }] = useCreateEstimateLinesMutation({
 *   variables: {
 *      lines: // value for 'lines'
 *      repairEstimateId: // value for 'repairEstimateId'
 *   },
 * });
 */
export function useCreateEstimateLinesMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateEstimateLinesDocument, options);
}
export const DeclineRepairEstimateDocument = gql `
    mutation declineRepairEstimate($repairEstimateId: Long!) {
  declineRepairEstimate(input: {repairEstimateId: $repairEstimateId}) {
    successPayload {
      success
    }
  }
}
    `;
/**
 * __useDeclineRepairEstimateMutation__
 *
 * To run a mutation, you first call `useDeclineRepairEstimateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineRepairEstimateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineRepairEstimateMutation, { data, loading, error }] = useDeclineRepairEstimateMutation({
 *   variables: {
 *      repairEstimateId: // value for 'repairEstimateId'
 *   },
 * });
 */
export function useDeclineRepairEstimateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeclineRepairEstimateDocument, options);
}
export const DeleteRepairEstimateImageDocument = gql `
    mutation DeleteRepairEstimateImage($repairEstimateId: Long!, $imageId: String!) {
  deleteRepairEstimateImage(
    input: {repairEstimateId: $repairEstimateId, imageId: $imageId}
  ) {
    successPayload {
      success
    }
  }
}
    `;
/**
 * __useDeleteRepairEstimateImageMutation__
 *
 * To run a mutation, you first call `useDeleteRepairEstimateImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRepairEstimateImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRepairEstimateImageMutation, { data, loading, error }] = useDeleteRepairEstimateImageMutation({
 *   variables: {
 *      repairEstimateId: // value for 'repairEstimateId'
 *      imageId: // value for 'imageId'
 *   },
 * });
 */
export function useDeleteRepairEstimateImageMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteRepairEstimateImageDocument, options);
}
export const GetRepairEstimateDocument = gql `
    query GetRepairEstimate($id: Long!) {
  repairEstimate(id: $id) {
    id
    claimId
    estimateStatus
    paymentStatus
    submissionNumber
    currentEstimatedCompletionDate
    enteredOdometer
    orderId
    vehicle {
      id
      year
      make
      model
      vin
      holmanUnitNo
      engineType
      licensePlate
      color
    }
    client {
      id
      clientName
    }
    driverContact {
      name
      phone
      email
    }
    images {
      fileName
      thumbnailUrl
      imageUrl
      submissionNumber
      createdDate
    }
    lineItems {
      productCode
      unitPrice
      productDescription
      createdDate
      quantity
      extendedEstimate
    }
    estimateDocuments {
      fileName
      documentUrl
      submissionNumber
      createdDate
    }
    vendorClaimNotes {
      claimNoteNumber
      createdDate
      notes
    }
  }
}
    `;
/**
 * __useGetRepairEstimateQuery__
 *
 * To run a query within a React component, call `useGetRepairEstimateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepairEstimateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepairEstimateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRepairEstimateQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetRepairEstimateDocument, options);
}
export function useGetRepairEstimateLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetRepairEstimateDocument, options);
}
export function useGetRepairEstimateSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetRepairEstimateDocument, options);
}
export const GetRepairEstimateImagesDocument = gql `
    query GetRepairEstimateImages($repairEstimateId: Long!, $submissionNumber: Int!) {
  repairEstimateImages(
    repairEstimateId: $repairEstimateId
    submissionNumber: $submissionNumber
  ) {
    fileName
    thumbnailUrl
    imageUrl
    submissionNumber
    createdDate
  }
}
    `;
/**
 * __useGetRepairEstimateImagesQuery__
 *
 * To run a query within a React component, call `useGetRepairEstimateImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepairEstimateImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepairEstimateImagesQuery({
 *   variables: {
 *      repairEstimateId: // value for 'repairEstimateId'
 *      submissionNumber: // value for 'submissionNumber'
 *   },
 * });
 */
export function useGetRepairEstimateImagesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetRepairEstimateImagesDocument, options);
}
export function useGetRepairEstimateImagesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetRepairEstimateImagesDocument, options);
}
export function useGetRepairEstimateImagesSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetRepairEstimateImagesDocument, options);
}
export const RequestApprovalForRepairEstimateSubmissionDocument = gql `
    mutation RequestApprovalForRepairEstimateSubmission($repairEstimateId: Long!, $submissionNumber: Int!) {
  requestApprovalForRepairEstimateSubmission(
    input: {repairEstimateId: $repairEstimateId, submissionNumber: $submissionNumber}
  ) {
    successPayload {
      success
    }
  }
}
    `;
/**
 * __useRequestApprovalForRepairEstimateSubmissionMutation__
 *
 * To run a mutation, you first call `useRequestApprovalForRepairEstimateSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestApprovalForRepairEstimateSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestApprovalForRepairEstimateSubmissionMutation, { data, loading, error }] = useRequestApprovalForRepairEstimateSubmissionMutation({
 *   variables: {
 *      repairEstimateId: // value for 'repairEstimateId'
 *      submissionNumber: // value for 'submissionNumber'
 *   },
 * });
 */
export function useRequestApprovalForRepairEstimateSubmissionMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RequestApprovalForRepairEstimateSubmissionDocument, options);
}
export const SubmitAdditionalImagesDocument = gql `
    mutation SubmitAdditionalImages($repairEstimateId: Long!, $submissionNumber: Int!) {
  submitAdditionalImages(
    input: {repairEstimateId: $repairEstimateId, submissionNumber: $submissionNumber}
  ) {
    successPayload {
      success
    }
  }
}
    `;
/**
 * __useSubmitAdditionalImagesMutation__
 *
 * To run a mutation, you first call `useSubmitAdditionalImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAdditionalImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAdditionalImagesMutation, { data, loading, error }] = useSubmitAdditionalImagesMutation({
 *   variables: {
 *      repairEstimateId: // value for 'repairEstimateId'
 *      submissionNumber: // value for 'submissionNumber'
 *   },
 * });
 */
export function useSubmitAdditionalImagesMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SubmitAdditionalImagesDocument, options);
}
export const UpdateRepairEstimateEtaDocument = gql `
    mutation UpdateRepairEstimateEta($repairEstimateId: Long!, $estimatedCompletionDate: DateTime!, $noteText: String!) {
  updateRepairEstimateEta(
    input: {repairEstimateId: $repairEstimateId, estimatedCompletionDate: $estimatedCompletionDate, noteText: $noteText}
  ) {
    successPayload {
      success
    }
  }
}
    `;
/**
 * __useUpdateRepairEstimateEtaMutation__
 *
 * To run a mutation, you first call `useUpdateRepairEstimateEtaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRepairEstimateEtaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRepairEstimateEtaMutation, { data, loading, error }] = useUpdateRepairEstimateEtaMutation({
 *   variables: {
 *      repairEstimateId: // value for 'repairEstimateId'
 *      estimatedCompletionDate: // value for 'estimatedCompletionDate'
 *      noteText: // value for 'noteText'
 *   },
 * });
 */
export function useUpdateRepairEstimateEtaMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateRepairEstimateEtaDocument, options);
}
export const GetRepairEstimatesDocument = gql `
    query GetRepairEstimates($vendorId: String!, $paymentStatuses: [PaymentStatus!]!, $estimateStatuses: [EstimateStatus!]!) {
  repairEstimates(
    vendorId: $vendorId
    estimateStatuses: $estimateStatuses
    paymentStatuses: $paymentStatuses
  ) {
    id
    claimId
    estimateStatus
    paymentStatus
    vehicleId
    submissionNumber
    currentEstimatedCompletionDate
    vehicle {
      id
      year
      make
      model
      vin8
    }
  }
}
    `;
/**
 * __useGetRepairEstimatesQuery__
 *
 * To run a query within a React component, call `useGetRepairEstimatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepairEstimatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepairEstimatesQuery({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *      paymentStatuses: // value for 'paymentStatuses'
 *      estimateStatuses: // value for 'estimateStatuses'
 *   },
 * });
 */
export function useGetRepairEstimatesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetRepairEstimatesDocument, options);
}
export function useGetRepairEstimatesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetRepairEstimatesDocument, options);
}
export function useGetRepairEstimatesSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetRepairEstimatesDocument, options);
}
export const GetRepairEstimateSearchDocument = gql `
    query getRepairEstimateSearch($vendorId: String!, $duration: Int, $claimId: Long) {
  repairEstimateSearch(
    vendorId: $vendorId
    duration: $duration
    claimId: $claimId
  ) {
    id
  }
}
    `;
/**
 * __useGetRepairEstimateSearchQuery__
 *
 * To run a query within a React component, call `useGetRepairEstimateSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepairEstimateSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepairEstimateSearchQuery({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *      duration: // value for 'duration'
 *      claimId: // value for 'claimId'
 *   },
 * });
 */
export function useGetRepairEstimateSearchQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetRepairEstimateSearchDocument, options);
}
export function useGetRepairEstimateSearchLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetRepairEstimateSearchDocument, options);
}
export function useGetRepairEstimateSearchSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetRepairEstimateSearchDocument, options);
}
export const GetRepairEstimateCountDocument = gql `
    query GetRepairEstimateCount($vendorId: String!, $estimateStatuses: [EstimateStatus!]!, $paymentStatuses: [PaymentStatus!]!) {
  repairEstimateStatistics(
    vendorId: $vendorId
    estimateStatuses: $estimateStatuses
    paymentStatuses: $paymentStatuses
  ) {
    pendingRepairEstimateCount
  }
}
    `;
/**
 * __useGetRepairEstimateCountQuery__
 *
 * To run a query within a React component, call `useGetRepairEstimateCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepairEstimateCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepairEstimateCountQuery({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *      estimateStatuses: // value for 'estimateStatuses'
 *      paymentStatuses: // value for 'paymentStatuses'
 *   },
 * });
 */
export function useGetRepairEstimateCountQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetRepairEstimateCountDocument, options);
}
export function useGetRepairEstimateCountLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetRepairEstimateCountDocument, options);
}
export function useGetRepairEstimateCountSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetRepairEstimateCountDocument, options);
}
export const AssignVendorContactToOrderDocument = gql `
    mutation AssignVendorContactToOrder($orderId: Long!, $vendorContactId: Long!) {
  assignVendorContactToOrder(
    input: {orderId: $orderId, vendorContactId: $vendorContactId}
  ) {
    successPayload {
      success
    }
  }
}
    `;
/**
 * __useAssignVendorContactToOrderMutation__
 *
 * To run a mutation, you first call `useAssignVendorContactToOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignVendorContactToOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignVendorContactToOrderMutation, { data, loading, error }] = useAssignVendorContactToOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      vendorContactId: // value for 'vendorContactId'
 *   },
 * });
 */
export function useAssignVendorContactToOrderMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AssignVendorContactToOrderDocument, options);
}
export const CreateVendorContactDocument = gql `
    mutation CreateVendorContact($emailAddress: String!, $extension: String!, $name: String!, $phoneNumber: String!, $vendorId: String!) {
  createVendorApprovalContact(
    input: {emailAddress: $emailAddress, extension: $extension, name: $name, phoneNumber: $phoneNumber, vendorId: $vendorId}
  ) {
    createContactPayload {
      success
      vendorContactId
    }
  }
}
    `;
/**
 * __useCreateVendorContactMutation__
 *
 * To run a mutation, you first call `useCreateVendorContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVendorContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVendorContactMutation, { data, loading, error }] = useCreateVendorContactMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      extension: // value for 'extension'
 *      name: // value for 'name'
 *      phoneNumber: // value for 'phoneNumber'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useCreateVendorContactMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateVendorContactDocument, options);
}
export const DeleteVendorContactDocument = gql `
    mutation DeleteVendorContact($vendorContactId: Long!) {
  deleteVendorApprovalContact(input: {vendorContactId: $vendorContactId}) {
    successPayload {
      success
    }
  }
}
    `;
/**
 * __useDeleteVendorContactMutation__
 *
 * To run a mutation, you first call `useDeleteVendorContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVendorContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVendorContactMutation, { data, loading, error }] = useDeleteVendorContactMutation({
 *   variables: {
 *      vendorContactId: // value for 'vendorContactId'
 *   },
 * });
 */
export function useDeleteVendorContactMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteVendorContactDocument, options);
}
export const GetVendorContactsDocument = gql `
    query GetVendorContacts($id: String!) {
  vendor(id: $id) {
    vendorContacts {
      emailAddress
      extension
      id
      name
      notifyApprovals
      notifyPayments
      phoneNumber
      type
      vendorId
    }
  }
}
    `;
/**
 * __useGetVendorContactsQuery__
 *
 * To run a query within a React component, call `useGetVendorContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorContactsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVendorContactsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetVendorContactsDocument, options);
}
export function useGetVendorContactsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetVendorContactsDocument, options);
}
export function useGetVendorContactsSuspenseQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetVendorContactsDocument, options);
}
export const UpdateVendorContactDocument = gql `
    mutation UpdateVendorContact($emailAddress: String, $extension: String, $name: String, $phoneNumber: String, $vendorContactId: Long!) {
  updateVendorApprovalContact(
    input: {emailAddress: $emailAddress, extension: $extension, name: $name, phoneNumber: $phoneNumber, vendorContactId: $vendorContactId}
  ) {
    successPayload {
      success
    }
  }
}
    `;
/**
 * __useUpdateVendorContactMutation__
 *
 * To run a mutation, you first call `useUpdateVendorContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorContactMutation, { data, loading, error }] = useUpdateVendorContactMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      extension: // value for 'extension'
 *      name: // value for 'name'
 *      phoneNumber: // value for 'phoneNumber'
 *      vendorContactId: // value for 'vendorContactId'
 *   },
 * });
 */
export function useUpdateVendorContactMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateVendorContactDocument, options);
}
