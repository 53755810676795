import React,{ useState,useRef} from 'react';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage,RawIntlProvider,injectIntl } from 'react-intl';
import { Col, Form, Input, OverlayTrigger, Popover, Row, Spinner } from 'react-bootstrap';
import './OdometerUpdate.css';
import * as helperFunctions from 'utils/HelperFunctions';
import DynamicNumber from 'react-dynamic-number';
import classnames from 'classnames';
import {falseIfUndefined,getFormattedPersonaUserName} from 'utils/HelperFunctions';
import *  as fieldValidations from 'utils/FieldValidations';
import { useAppState } from 'shared/AppState/app-state';
import { useCreateVendorContactMutation, useGetVendorContactsQuery, useUpdateVendorContactMutation } from 'shared/types/__generated__/graphql-type.ts';
import styled from "styled-components";
import { PatternFormat, removePatternFormat } from 'react-number-format';
import * as yup from "yup";
import { useFormik } from 'formik';
import * as constants from 'constants/App';
import FormWrapper from 'shared/components/FormWrapper';

const ContactSelect = styled(Form.Select)`
    width: 100%;
    padding: 0 .5rem 0 .25rem;
    font-size: inherit;
    border-radius: 0;
    display: inline-block;
    margin-top: 20px;
    height: 25px;
    text-overflow: ellipsis;
    border: 1px solid #5587B7;
    &:focus {
        border-color: #5587B7;
        outline: inherit;
        box-shadow: inherit;
    }
`;

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

let contactSchema = yup.object({
  contactName: yup.string()
    .trim()
    .required("service_contact__name_required")
    .matches(/^[A-z\s]*$/, "service_contact__name_required"),
  contactEmail: yup.string()
    .trim()
    .email("service_contact__email_required")
    .required("service_contact__email_required"),
  contactPhone: yup.string()
    .trim()
    .matches(phoneRegExp, "service_contact__phone_required")
    .required("service_contact__phone_required"),
  contactExt: yup.string()
    .trim()
    .optional()
    .matches(/^[0-z]*$/, "service_contact__ext_required")
});

const OdometerUpdate = (props) => {  
    const emptyContact = {
        name: null,
        emailAddress: null,
        phoneNumber: null,
        extension: null,
        id: null
    };

    const [appState, dispatch] = useAppState();
    const [hourMeterUpdated,setHourMeterUpdated]=useState(false);
    const [odometerErrorMessage,setOdometerErrorMessage]=useState(undefined);
    const [engineHoursErrorMessage,setEngineHoursErrorMessage]=useState(undefined);
    const [nonRoadVehicleErrorMessage, setNonRoadVehicleErrorMessage]=useState(undefined);
    const [refNumberErrorMessage,setRefNumberErrorMessage]=useState(undefined);
    const [overlayOdometerShown, setOverlayOdometerShown]=useState(false);
    const [overlayEngineHoursShown, setOverlayEngineHoursShown]=useState(false);
    const [overlayShopShown, setOverlayShopShown]=useState(false);
    const [overlayRefNumberShown, setOverlayRefNumberShown]=useState(false);
    const [overlayContactShown, setOverlayContactShown] = useState(false);
    const [selectedContact, setSelectedContract] = useState(emptyContact);
    const [formErrors, setFormErrors] = useState([]);
    const txtEngineHours=useRef(null);
    const txtOdometer=useRef(null);
    const txtRefNumber=useRef(null);
    const txtReqName=useRef(null);
    const txtClientRefNumber=useRef(null);

    const { data: contactsData, loading: contactsLoading, error: contactsError } = useGetVendorContactsQuery({
        variables: {
            id: props.vendorId
        },
        client: props.vendorClient,
        onError(error, clientOptions) {
            setFormErrors(helperFunctions.getGenericErrorObject());
        },
        onCompleted() {
            setFormErrors([]);
        }
    });
    const [createVendorContactMutation, { data: createData, loading: createLoading, error: createError }] = useCreateVendorContactMutation({
        client: props.vendorClient,
        onError(error, clientOptions) {
            setFormErrors(helperFunctions.getGenericErrorObject());
        },
        onCompleted() {
            setFormErrors([]);
        }
    });
    const [updateVendorContactMutation, { data: updateData, loading: updateLoading, error: updateError }] = useUpdateVendorContactMutation({
        client: props.vendorClient,
        onError(error, clientOptions) {
            setFormErrors(helperFunctions.getGenericErrorObject());
        },
        onCompleted() {
            setFormErrors([]);
        }
      });
    const formik = useFormik({
        initialValues: {
            contactName: selectedContact.name? selectedContact.name: "",
            contactEmail: selectedContact.emailAddress? selectedContact.emailAddress: "",
            contactPhone: selectedContact.phoneNumber? selectedContact.phoneNumber: "",
            contactExt: selectedContact.extension? selectedContact.extension: ""
        },
        validationSchema: contactSchema,
        validateOnChange: false,
        enableReinitialize: true,
    });

   const onVehicleAtShopChangeHandler=(value, event)=> {
        event.preventDefault();
        var odometerData = Map({
            isVehicleAtShop: value
        });

        props.onVehicleAtShopSelectionChange(value);
    };

    const validateOdometerDetails = ()=> {

        var result = helperFunctions.validateOdometerDetails(txtOdometer.current,
            txtEngineHours.current,
            hourMeterUpdated,
            props.vehicleDetails);

        //udpate 
        if (result.updateEngineHoursWithOdometer)
            txtEngineHours.current.value = txtOdometer.current.value;

        if (result.HourMeterUpdated)            
            setHourMeterUpdated(true);

        if (result.NonRoadVehicleErrorMessageUpdated)
            setNonRoadVehicleErrorMessage(result.NonRoadVehicleErrorMessage);

        if (result.OdometerErrorMessageUpdated)
            setOdometerErrorMessage(result.OdometerErrorMessage);
            

        const isErrorMessageSet = result.isErrorMessageSet;

        if (isErrorMessageSet) {
            setOverlayOdometerShown(true);
            return false;
        }
        else {            
            setOdometerErrorMessage(undefined);
            setOverlayOdometerShown(false);

        }
        return true;
    }
    const ontxtOdometerChange=(event)=> {

        event.preventDefault();

        if (props.odometerData.get('newOdometer') != txtOdometer.current.value) {
               
            validateOdometerDetails();
                saveOdometerDetails();
       
        }

    }

   const ontxtEngineHoursChange=(event)=> {
        event.preventDefault();
        if (props.odometerData.get('newEngineHours') != txtEngineHours.current.value) {
          
            validateEngineHours();
            saveOdometerDetails();
        }
    }

    const validateEngineHours=() =>{
        const result = helperFunctions.validateEngineHours(txtEngineHours.current, props.vehicleDetails);

        setEngineHoursErrorMessage(result.EngineHoursErrorMessage);

        if (result.showOverlayEngineHours)
            setOverlayEngineHoursShown(true);
        else
            setOverlayEngineHoursShown(false);

        return result.isValid;
    }

    const validateOdometerDetails1=()=> {
        var result = helperFunctions.validateOdometerDetails(txtOdometer,
            txtEngineHours,
            hourMeterUpdated,
            props.vehicleDetails);

        //udpate 
        if (result.updateEngineHoursWithOdometer)
            txtEngineHours.current.value= txtOdometer.current.value;

        if (result.HourMeterUpdated)            
            setHourMeterUpdated(true);

        if (result.NonRoadVehicleErrorMessageUpdated)
            setNonRoadVehicleErrorMessage(result.NonRoadVehicleErrorMessage);

        if (result.OdometerErrorMessageUpdated)
            setOdometerErrorMessage(result.OdometerErrorMessage);

        const isErrorMessageSet = result.isErrorMessageSet;

        if (isErrorMessageSet) {
            setOverlayOdometerShown(true);
            return false;
        }
        else {
            setOdometerErrorMessage(undefined);
            setOverlayOdometerShown(false);

        }
        return true;
    }

    const saveOdometerDetails=()=> {
        props.onSaveVehicleDetails(txtOdometer.current.value, txtEngineHours.current.value, fieldValidations.isVisible(props.fieldValidations, 'REFERENCE_NO') ? txtRefNumber.current.value: '', selectedContact.name,txtClientRefNumber.current.value);

    }
   
    const onSaveUpdateClick= async (event)=> {
        setOverlayContactShown(false);
        event.preventDefault();

        saveOdometerDetails();

        setOdometerErrorMessage('ododmeter_Validation_Message_6');
        setOverlayOdometerShown(false);
        setOverlayShopShown(false);
         var refNumberCheck  = true, odometerCheck  = true ,isVehicleAtShopCheck  = true, contactCheck = true;

         contactCheck = await validateContact();

         if (fieldValidations.isVisible(props.fieldValidations, 'REFERENCE_NO')) {
            setOverlayRefNumberShown(false);
             const referenceNumberValidationErrorMessage = fieldValidations.getValidationErrorMessage(props.fieldValidations, 'REFERENCE_NO', txtRefNumber.current.value);
             if (referenceNumberValidationErrorMessage !== null) {
                 setRefNumberErrorMessage(referenceNumberValidationErrorMessage);
                 setOverlayRefNumberShown(true);
                 refNumberCheck = false;
             } else {
                 setRefNumberErrorMessage(undefined);
                 setOverlayRefNumberShown(false);
                 refNumberCheck = true;
             }
         } 

        if (props.odometerData.get('newOdometer') == undefined || props.odometerData.get('newOdometer').trim().length === 0 || (props.odometerData.get('newOdoisVehicleAtShopmeter'))) {
            setOverlayOdometerShown(true);
            odometerCheck = false;
        } else {
            setOdometerErrorMessage(undefined);

            setOverlayOdometerShown(false);
            odometerCheck = true;
        }
        if ( props.odometerData.get('isVehicleAtShop') == undefined) {
            setOverlayShopShown(true);
            isVehicleAtShopCheck = false;
        } else {
            setOverlayShopShown(false);
            isVehicleAtShopCheck = true;
        }
        if(refNumberCheck && odometerCheck && isVehicleAtShopCheck && contactCheck ){
            const replaceRegex = /[() ]/g;
            var phoneNumber = formik.values.contactPhone.replace(replaceRegex, "");
            if (selectedContact.id == -1) {
                await createVendorContactMutation({
                    variables: {
                        name: formik.values.contactName,
                        emailAddress: formik.values.contactEmail,
                        phoneNumber: phoneNumber,
                        extension: formik.values.contactExt,
                        vendorId: props.vendorId
                    }
                }).then((result) => {
                    let payload = result.data.createVendorApprovalContact.createContactPayload;
                    if(payload.success){
                        props.onSaveUpdateClick(payload.vendorContactId);
                    }
                });
            }
            else{
                var oldContactData = contactsData.vendor.vendorContacts.find(c => c.id == selectedContact.id);
                if(isContactUpdated(oldContactData, phoneNumber)){
                    await updateVendorContactMutation({
                        variables: {
                            vendorContactId: selectedContact.id,
                            name: formik.values.contactName,
                            emailAddress: formik.values.contactEmail,
                            phoneNumber: phoneNumber,
                            extension: formik.values.contactExt
                        }
                    }).then(() => {
                        props.onSaveUpdateClick(selectedContact.id);
                    });
                }
                else{
                    props.onSaveUpdateClick(selectedContact.id);
                }
            }
        }
    }

    const resetMaxValue=(maxLength, event)=> {
        event.preventDefault();

        if (event.target.value != undefined && event.target.value.length > maxLength) {
            event.target.value = event.target.value.slice(0, maxLength);
        }
    }   
        
    const onSelectContact = (e) => {
        setOverlayContactShown(false);
        let contactId = e.target.value;
        if(contactId != -1 && contactId != ""){
            let contactData = contactsData.vendor.vendorContacts.find(c => c.id == contactId);
            setSelectedContract({
                name: contactData.name,
                emailAddress: contactData.emailAddress,
                phoneNumber: contactData.phoneNumber,
                extension: contactData.extension,
                id: contactData.id
            });
        }
        else{
            setSelectedContract({
                name: null,
                emailAddress: null,
                phoneNumber: null,
                extension: null,
                id: contactId
            });
        }
    }

    const validateContact = async () => {
        if(selectedContact.id === "" || selectedContact.id === null){
            setOverlayContactShown(true);
            return false;
        }
        else{
            return formik.validateForm().then((formErrors) => {
                if(_.isEmpty(formErrors)){
                    return true;
                }
                else
                    return false;
            },
            () => {return false;});
        }
    }

    const isContactUpdated = (oldContact, phoneNumber) => {
        return oldContact.name != formik.values.contactName || oldContact.emailAddress != formik.values.contactEmail || oldContact.phoneNumber != phoneNumber || oldContact.extension != (formik.values.contactExt == "" ? null : formik.values.contactExt)
    }


        /* Globalization of labels */
        const messages = defineMessages({
            odometer_details_caption: {
                id: 'odometer_details_caption',
                description: 'Required updates',
                defaultMessage: 'Required updates'
            },
            odometer_details_odometer: {
                id: 'odometer_details_odometer',
                description: 'Odometer',
                defaultMessage: 'Odometer'
            },
            odometer_details_last_reading: {
                id: 'odometer_details_last_reading',
                description: 'Last reading',
                defaultMessage: 'Last reading'
            },
            odometer_details_engine_hours: {
                id: 'odometer_details_engine_hours',
                description: 'Hour meter',
                defaultMessage: 'Hour meter'
            },
            odometer_details_optional: {
                id: 'odometer_details_optional',
                description: 'optional display',
                defaultMessage: ' (if equipped)'
            },
            odometer_details_select_one: {
                id: 'odometer_details_select_one',
                description: 'Select One',
                defaultMessage: 'Select One'
            },
            odometer_details_at_shop: {
                id: 'odometer_details_at_shop',
                description: 'Vehicle is at the shop',
                defaultMessage: 'Vehicle is at the shop'
            },
            odometer_details_not_at_shop: {
                id: 'odometer_details_not_at_shop',
                description: 'Vehicle is not at the shop',
                defaultMessage: 'Vehicle is not at the shop'
            },
            odometer_details_save_update: {
                id: 'odometer_details_save_update',
                description: 'Confirm Vehicle and go to the next step',
                defaultMessage: 'Confirm Vehicle and go to the next step'
            },
            odometer_details_unavailable: {
                id: 'odometer_details_unavailable',
                description: 'Unavailable',
                defaultMessage: 'Unavailable'
            },
            ododmeter_Validation_Message_1: {
                id: 'ododmeter_Validation_Message_1',
                description: 'Warning: The meter entered {newOdometer} differs from the estimated meter by:{odometerDifference}.',
                defaultMessage: 'Warning: The meter entered {newOdometer} differs from the estimated meter by:{odometerDifference}.'
            },
            ododmeter_Validation_Message_2: {
                id: 'ododmeter_Validation_Message_2',
                description: 'Warning: The meter entered exceeds the estimated meter by: {newOdometer}.',
                defaultMessage: 'Warning: The meter entered exceeds the estimated meter by: {newOdometer}.'
            },
            ododmeter_Validation_Message_3: {
                id: 'ododmeter_Validation_Message_3',
                description: 'Warning: The meter entered {newOdometer} differs from the expected meter {estimatedOdometer} for {currentDate} by:{estimatedOdometerDiff}.',
                defaultMessage: 'Warning: The meter entered {newOdometer} differs from the expected meter {estimatedOdometer} for {currentDate} by:{estimatedOdometerDiff}.'
            },
            ododmeter_Validation_Message_4: {
                id: 'ododmeter_Validation_Message_4',
                description: 'Warning: The meter entered {newOdometer} differs from the estimated meter by: {odometerDifference}.',
                defaultMessage: 'Warning: The meter entered {newOdometer} differs from the estimated meter by: {odometerDifference}.'
            },
            ododmeter_Validation_Message_5: {
                id: 'ododmeter_Validation_Message_5',
                description: 'Warning: This Unit is identified as EQUIPMENT. Odometer reading will be copied to Hour Meter reading based on client needs.',
                defaultMessage: 'Warning: This Unit is identified as EQUIPMENT. Odometer reading will be copied to Hour Meter reading based on client needs.'
            },
            hourmeter_Validation_Message: {
                id: 'hourmeter_Validation_Message',
                description: 'Warning: Hour meter cannot be less than last recorded value.',
                defaultMessage: 'Warning: Hour meter cannot be less than last recorded value.'
            },
            ododmeter_Validation_Message_6: {
                id: 'ododmeter_Validation_Message_6',
                description: 'Please enter Odometer value',
                defaultMessage: 'Please enter Odometer value'
            },
            shop_select: {
                id: 'shop_select',
                description: 'Odometer shop or not selection error message',
                defaultMessage: 'Please Select One!'
            },
            driver_validation_text: {
                id: 'driver_validation_text',
                description: 'validation warning for drivers who need to call Holman',
                defaultMessage: 'This purchase order requires driver validation. Advise driver to validate repairs with Holman prior to performing work.s'

            },
            driver_reference_number_title: {
                id: 'driver_reference_number_title',
                description: 'title for reference number section',
                defaultMessage: 'Your reference#'
            },
            driver_reference_number_subTitle: {
                id: 'driver_reference_number_subTitle',
                description: 'subtitle for reference number section',
                defaultMessage: 'Invoice#/RO#/WO#'
            },
            po_requester_title: {
                id: 'po_requester_title',
                description: 'Shop Contact For This Repair',
                defaultMessage: 'Shop Contact For This Repair'
            },
            reference_number_validation_message: {
                id: 'reference_number_validation_message',
                description: 'reference_number_validation_message',
                defaultMessage: 'Please input your 6 digit reference number'
            },
            client_po_title: {
                id: 'client_po_title',
                description: 'client_po_title',
                defaultMessage: 'Client-provided PO#'
            },
            client_po_subtitle: {
                id: 'client_po_subtitle',
                description: 'client_po_subtitle',
                defaultMessage: '(if available)'
            },
            service_contact_name: {
                id: 'service_contact_name',
                description: 'Service Contact Name',
                defaultMessage: 'Service Contact Name'
            },
            service_contact_phone: {
                id: 'service_contact_phone',
                description: 'Service Contact Phone',
                defaultMessage: 'Service Contact Phone'
            },
            service_contact_ext: {
                id: 'service_contact_ext',
                description: 'Ext.',
                defaultMessage: 'Ext.'
            },
            service_contact_email: {
                id: 'service_contact_email',
                description: 'Service Contact Email',
                defaultMessage: 'Service Contact Email'
            },
            service_contact_new_contact: {
                id: 'service_contact_new_contact',
                description: 'Create New Contact',
                defaultMessage: 'Create New Contact'
            },
            service_contact_new_contact_body: {
                id: 'service_contact_new_contact_body',
                description: 'Please enter new contact information below:',
                defaultMessage: 'Please enter new contact information below:'
            },
            service_contact__name_required: {
                id: 'service_contact__name_required',
                description: 'Please enter a valid name',
                defaultMessage: 'Please enter a valid name'
            },
            service_contact__email_required: {
                id: 'service_contact__email_required',
                description: 'Please enter a valid email',
                defaultMessage: 'Please enter a valid email'
            },
            service_contact__phone_required: {
                id: 'service_contact__phone_required',
                description: 'Please enter a valid phone number',
                defaultMessage: 'Please enter a valid phone number'
            },
            service_contact__ext_required: {
                id: 'service_contact__ext_required',
                description: 'Please enter a valid phone extension',
                defaultMessage: 'Please enter a valid phone extension'
            },
            service_contact_required: {
                id: 'service_contact_required',
                description: 'Please select a contact for this repair',
                defaultMessage: 'Please select a contact for this repair'
            },
            service_contact_edit_contact_body: {
                id: 'service_contact_edit_contact_body',
                description: 'Shop repair contact details below. Please make any necessary changes and continue to next step:',
                defaultMessage: 'Shop repair contact details below. Please make any necessary changes and continue to next step:'
            },
            service_contact__name_sublabel: {
                id: 'service_contact__name_sublabel',
                description: '(First and Last name)',
                defaultMessage: '(First and Last name)'
              },
        });

        const odometerData = props.odometerData.toJSON();
        const vehicleDetails = props.vehicleDetails.toJSON();
        
        return (
        <div className="container-fluid odometer_update_box">
            <FormWrapper key='odometerUpdateErrorMessage' id="odometerUpdateErrorMessage" {...props} formErrors={formErrors} />
            <div className="row odometer_update__caption">
                <div className="col-md-12">
                    <FormattedMessage {...messages.odometer_details_caption} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {props.driverValidationFlag ?
                        <div className="odometer_driver-validation__div-placement">
                            <div className="odometer_driver-validation__div">
                                <div className="odometer_driver-validation__text">
                                    <FormattedMessage {...messages.driver_validation_text} />
                                </div>
                            </div>
                        </div>
                        : undefined
                    }
                    <div className="row">
                        <div className="col-md-4 odometer_update_col__label">
                            <FormattedMessage {...messages.odometer_details_odometer} />
                        </div>
                        <div className="col-md-3">
                            <OverlayTrigger show={overlayOdometerShown}
                                placement="right"
                                trigger="manual"

                                overlay={
                                    <Popover id="popOverOdometer" className="ErrorMessage">
                                       <RawIntlProvider value={props.intl}>
                                        {odometerErrorMessage == undefined ? false :
                                            <FormattedMessage
                                                values={{
                                                    newOdometer: txtOdometer.current.value,
                                                    odometerDifference: (txtOdometer.current.value != '' && props.vehicleDetails.toJSON().lastOdometerReading != undefined && props.vehicleDetails.toJSON().lastOdometerReading != '') ?
                                                        Number(txtOdometer.current.value) - Number(props.vehicleDetails.toJSON().lastOdometerReading) : '',

                                                    estimatedOdometer: props.vehicleDetails.toJSON().estimatedOdometer,
                                                    currentDate: (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear(),
                                                    estimatedOdometerDiff: props.vehicleDetails.toJSON().estimatedOdometer != undefined && props.vehicleDetails.toJSON().estimatedOdometer != '' ? Number(props.vehicleDetails.toJSON().estimatedOdometer) - (txtOdometer.current.value == undefined ? 0 : Number(txtOdometer.current.value)) : ''

                                                }}

                                                {...messages[odometerErrorMessage]}
                                            />}
                                        {nonRoadVehicleErrorMessage != undefined && odometerErrorMessage != undefined ?
                                            <br /> : ''}
                                        {nonRoadVehicleErrorMessage == undefined ? false :
                                            <FormattedMessage {...messages[nonRoadVehicleErrorMessage]} />}
                                             </RawIntlProvider>
                                    </Popover>}

                            >

                                <DynamicNumber
                                    className="odometer_update__input-box"
                                    onInput={resetMaxValue.bind(this, 6)}
                                    positive={true}
                                    negative={false}
                                    thousand={false}
                                    fraction={0}
                                    onBlur={ontxtOdometerChange.bind(this)}
                                    value={odometerData.newOdometer !== undefined ? odometerData.newOdometer : ""}
                                    ref={txtOdometer}
                                />
                            </OverlayTrigger>


                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 odometer_update_col_unedit__label">
                            <FormattedMessage {...messages.odometer_details_last_reading} />
                        </div>
                        <div className="col-md-3 odometer_update_col_unedit__val">
                            {vehicleDetails.lastOdometerReading == undefined || vehicleDetails.lastOdometerReading == '' ?
                                <FormattedMessage {...messages.odometer_details_unavailable} /> : vehicleDetails.lastOdometerReading}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="odometer_update_col__label">
                                <FormattedMessage {...messages.odometer_details_engine_hours} />
                            </div>
                            <div className="odometer_update_col__label_optional">
                                <FormattedMessage {...messages.odometer_details_optional} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <OverlayTrigger show={overlayEngineHoursShown}
                                placement="right"
                                trigger="manual"


                                overlay={
                                    <Popover id="popOverEngineHours" className="ErrorMessage">
                                         <RawIntlProvider value={props.intl}>
                                        {engineHoursErrorMessage == undefined ? false :
                                            <FormattedMessage {...messages[engineHoursErrorMessage]} />}
                                            </RawIntlProvider>
                                    </Popover>}

                            >
                                <DynamicNumber
                                    className="odometer_update__input-box"
                                    onInput={resetMaxValue.bind(this, 6)}
                                    positive={true}
                                    negative={false}
                                    thousand={false}
                                    fraction={0}
                                    onBlur={ontxtEngineHoursChange.bind(this)}
                                    value={odometerData.newEngineHours != undefined ? odometerData.newEngineHours : ""}
                                    ref={txtEngineHours}
                                />

                            </OverlayTrigger>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 odometer_update_col_unedit__label">
                            <FormattedMessage {...messages.odometer_details_last_reading} />
                        </div>
                        <div className="col-md-3 odometer_update_col_unedit__val">
                            {vehicleDetails.lastHourMeterReading == undefined || vehicleDetails.lastHourMeterReading == '' ?
                                <FormattedMessage {...messages.odometer_details_unavailable} /> : vehicleDetails.lastHourMeterReading}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 odometer_update_select">
                            <FormattedMessage {...messages.odometer_details_select_one} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <OverlayTrigger show={overlayShopShown}
                                placement="right"
                                trigger="manual"


                                overlay={
                                    <Popover id="popOverShopYes" className="ErrorMessage">
                                             <RawIntlProvider value={props.intl}>
                                        <FormattedMessage {...messages.shop_select} />
                                        </RawIntlProvider>
                                    </Popover>}

                            >
                                <div className="odometer_update__radio"
                                    onClick={onVehicleAtShopChangeHandler.bind(this, true)}>
                                    <img src={!odometerData.isVehicleAtShop || odometerData.isVehicleAtShop == undefined
                                        ? "/assets/VMS_unchecked.png"
                                        : "/assets/VMS_checked.png"}
                                    />
                                    {' '} <FormattedMessage {...messages.odometer_details_at_shop} />

                                </div>
                            </OverlayTrigger>

                            <div className="odometer_update__radio"
                                onClick={onVehicleAtShopChangeHandler.bind(this, false)}>
                                <img src={odometerData.isVehicleAtShop || odometerData.isVehicleAtShop == undefined
                                    ? "/assets/VMS_unchecked.png"
                                    : "/assets/VMS_checked.png"}
                                />
                                {' '} <FormattedMessage {...messages.odometer_details_not_at_shop} />

                            </div>

                        </div>
                    </div>
                    
                    {fieldValidations.isVisible(props.fieldValidations,'REFERENCE_NO') ?
                    <div>
                    <div className="row">
                        <div className="col-md-4 ">
                            <div className="odometer_update_col__label">
                                <FormattedMessage {...messages.driver_reference_number_title} />
                            </div>
                        </div>
                        <OverlayTrigger show={overlayRefNumberShown}
                                        placement="right"
                                        trigger="manual"


                                        overlay={
                                            <Popover id="popOverRefNumber" className="ErrorMessage">
                                                  <RawIntlProvider value={props.intl}>
                                                {refNumberErrorMessage == undefined ? false :
                                                    refNumberErrorMessage}
                                                    </RawIntlProvider>
                                            </Popover>}

                        >

                        <div className="col-md-3">

                            <input className="odometer_update__input-box"
                                ref={txtRefNumber}
                                cols="6"
                                size="6"
                                maxLength="25"
                                onKeyUp={saveOdometerDetails.bind(this)}
                            />
                        </div>
                        </OverlayTrigger>
                    </div>
                    <div className="row">
                        <div className="col-md-4 odometer_update_col_unedit__label">
                            <FormattedMessage {...messages.driver_reference_number_subTitle} />
                        </div>
                    </div>
                    </div>
                   : undefined 
                   }         
                   <div className="row">
                        <div className="col-md-4 ">
                            <div className="odometer_clientPO_col__label">
                            <FormattedMessage {...messages.client_po_title} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <input className="odometer_update__input-box"
                                ref={txtClientRefNumber}
                                cols="6"
                                size="6"
                                maxLength="20"
                                value={txtClientRefNumber.current != null ? txtClientRefNumber.current.value.toUpperCase() : ""}
                                onChange={saveOdometerDetails.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 odometer_update_col__label_optional">
                        <FormattedMessage {...messages.client_po_subtitle} />
                        </div>
                    </div>

                    <Row>
                        <Col md={4}>
                            <div className="odometer_update_col__label">
                                <FormattedMessage {...messages.po_requester_title} />
                            </div>
                        </Col>
                        <Col md={4}>
                            <OverlayTrigger
                            show={overlayContactShown}
                            placement="right"
                            trigger="manual"
                            overlay={
                                <Popover id="popOverContact" className="ErrorMessage">
                                     <FormattedMessage {...messages.service_contact_required} />
                                </Popover>}
                            >
                            <ContactSelect id="contact_select_dropdown" value={selectedContact.id} onChange={(e) => onSelectContact(e)}>
                                <option id="select_empty" value={undefined}/>
                                {contactsData?.vendor?.vendorContacts?.map((c, i) => (
                                    <option style={{fontWeight:"600"}} key={i} value={c.id} id={`contact_${i}`}>{c.name ?? c.emailAddress}</option>
                                ))}
                                <hr/>
                                <option id="select_new_contact" style={{color:"#5587B7", fontWeight:"500", textAlign:"center"}} value={-1}><FormattedMessage {...messages.service_contact_new_contact} />&nbsp;&nbsp;</option>
                            </ContactSelect>
                            </OverlayTrigger>
                            {contactsLoading && <Spinner animation='border'/>}
                        </Col>
                    </Row>

                        {selectedContact.id != undefined && selectedContact.id != "" &&
                            <Form id="contactEditForm">
                                <Row>
                                    <Col style={{ paddingTop: "2rem" }}>
                                        {selectedContact.id == -1 ?
                                            <FormattedMessage {...messages.service_contact_new_contact_body} />
                                            : <FormattedMessage {...messages.service_contact_edit_contact_body} />}
                                    </Col>
                                </Row>
                                {(selectedContact.id == -1 || selectedContact.name == null) && <Row className='align-items-end'>
                                    <Col lg={4} md={4}>
                                        <div className="odometer_update_col__label">
                                            <FormattedMessage {...messages.service_contact_name} />
                                        </div>
                                        <div className="odometer_update_col__label_optional">
                                            <FormattedMessage {...messages.service_contact__name_sublabel} />
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4}>
                                        <OverlayTrigger
                                            show={formik.errors?.contactName != undefined}
                                            placement="right"
                                            trigger="manual"
                                            overlay={
                                                <Popover id="popOverContactName" className="ErrorMessage">
                                                    {formik.errors?.contactName != undefined && <FormattedMessage {...messages[formik.errors.contactName]} />}
                                                </Popover>}
                                        >
                                            <input className="odometer_update__input-box"
                                                id="contactName"
                                                name="contactName"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.contactName}
                                                style={{ width: "100%" }}
                                                maxLength={50} />
                                        </OverlayTrigger>
                                    </Col>
                                </Row>}
                                <Row>
                                    <Col lg={4}>
                                        <div className="odometer_update_col__label">
                                            <FormattedMessage {...messages.service_contact_phone} />
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <OverlayTrigger
                                            show={formik.errors?.contactPhone != undefined}
                                            placement="right"
                                            trigger="manual"
                                            overlay={
                                                <Popover id="popOverContactPhone" className="ErrorMessage">
                                                    {formik.errors?.contactPhone != undefined && <FormattedMessage {...messages[formik.errors.contactPhone]} />}
                                                </Popover>}
                                        >{({ref}) => (
                                            <PatternFormat
                                                className="odometer_update__input-box"
                                                format="(###) ### ####"
                                                allowEmptyFormatting
                                                mask="_"
                                                id="contactPhone"
                                                name="contactPhone"
                                                onChange={formik.handleChange}
                                                value={formik.values.contactPhone}
                                                style={{ width: "100%" }}
                                                maxLength={15}
                                                getInputRef={ref}
                                                type='tel' />
                                        )}
                                        </OverlayTrigger>
                                    </Col>
                                    <Col lg={{ span: "1", offset: "1" }}>
                                        <div className="odometer_update_col__label">
                                            <FormattedMessage {...messages.service_contact_ext} />
                                        </div>
                                    </Col>
                                    <Col lg={2}>
                                        <OverlayTrigger
                                            show={formik.errors?.contactExt != undefined}
                                            placement="right"
                                            trigger="manual"
                                            overlay={
                                                <Popover id="popOverContactExt" className="ErrorMessage">
                                                    {formik.errors?.contactExt != undefined && <FormattedMessage {...messages[formik.errors.contactExt]} />}
                                                </Popover>}
                                        >
                                            <input
                                                className="odometer_update__input-box"
                                                id="contactExt"
                                                name="contactExt"
                                                onChange={formik.handleChange}
                                                value={formik.values.contactExt}
                                                maxLength={7}
                                                style={{ width: "100%" }} />
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={4}>
                                        <div className="odometer_update_col__label">
                                            <FormattedMessage {...messages.service_contact_email} />
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <OverlayTrigger
                                            show={formik.errors?.contactEmail != undefined}
                                            placement="right"
                                            trigger="manual"
                                            overlay={
                                                <Popover id="popOverContactEmail" className="ErrorMessage">
                                                    {formik.errors?.contactEmail != undefined && <FormattedMessage {...messages[formik.errors.contactEmail]} />}
                                                </Popover>}
                                        >
                                            <input className="odometer_update__input-box"
                                                id="contactEmail"
                                                name="contactEmail"
                                                type="email"
                                                onChange={formik.handleChange}
                                                value={formik.values.contactEmail}
                                                style={{ width: "100%" }}
                                                maxLength={50} />
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                            </Form>}

                    <div className="row odometer_update__save" style={{textAlign:'right', marginTop:"2rem"}}>
                        <div className={classnames("row",
                            {"row" : props.selectedLocale === "en-US"},
                            {"odometer_updated__disabled" :props.isCreatingPO})}>
                            <a onClick={onSaveUpdateClick.bind(this)}>
                                <FormattedMessage {...messages.odometer_details_save_update} />
                                {' '}<img src="/assets/VMS_33button_go_bblue.png" />
                            </a>
                            <div className="inline-block">
                                {props.isCreatingPO ?
                                    <Spinner spinnerType="blue" />
                                    : undefined
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );    
};




export default injectIntl(OdometerUpdate);