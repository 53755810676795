import React, { forwardRef, useRef } from 'react';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage,FormattedNumber,RawIntlProvider,injectIntl} from 'react-intl';
import {Map, fromJS} from 'immutable';
import './CreatePOForm.css';
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import {emptyMapIfUndefined, emptyStringIfUndefined, falseIfUndefined, emptyStringIfNull, renderError, zeroIfUndefined} from 'utils/HelperFunctions';
import {Overlay, Popover} from 'react-bootstrap';
import DynamicNumber from 'react-dynamic-number';
import moment from 'moment';
import classNames from 'classnames';
import { PO_FIELD_TYPE,LINE_ITEM_TYPE,PO_FIELD_ERROR_TYPE } from 'constants/App';
import ReactDOM from 'react-dom';
import FormWrapper from 'components/FormWrapper';
import { IntlProvider } from 'react-intl';
import { DateUtils } from 'react-day-picker';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from '../../../../../shared/utils/MomentLocaleUtils';
import InputCalendar from 'components/InputCalendar';

const CreatePOForm = (props) => {
    const dtPickerServiceDate = useRef();
    const txtInvoiceNo = useRef();
    const txtCarWashRepairCost = useRef();
    const txtDetailCarWashRepairCost = useRef();
    const txtCarWashTax = useRef();
    const txtOdometer = useRef();
    const txtHST = useRef();
    const txtGST = useRef();
    const txtQST = useRef();
    const txtPST = useRef();

    moment.locale(props.userLocale);

    const onCreatePOValidation = () => {
        props.onCreatePOClick(dtPickerServiceDate.current.value,
            txtInvoiceNo.current.value,
            txtCarWashRepairCost.current.input.value,
            txtDetailCarWashRepairCost.current.input.value,
            txtCarWashTax.current !== undefined ?  txtCarWashTax.current.input.value : 0,
            txtOdometer.current.value,
            txtHST.current !== undefined ?  txtHST.current.input.value : 0,
            txtGST.current !== undefined ?  txtGST.current.input.value : 0,
            txtQST.current !== undefined ?  txtQST.current.input.value : 0,
            txtPST.current !== undefined ?  txtPST.current.input.value : 0,

        );

    }

    const resetMaxValue = (maxLength, event) => {
        event.preventDefault();

        if (event.target.value != undefined && event.target.value.length > maxLength) {
            event.target.value = event.target.value.slice(0, maxLength);
        }
    }


    const isCarWashRepairChangeHandler = (value, event) => {
        event.preventDefault();

        props.carWashRepairChange(value);
    }

    const onDateChangeHandler = (key, newDate) => {
        if (newDate !== undefined) {
            if (moment(newDate) >= moment().subtract(30, "days") && moment(newDate) <= moment().subtract(0, "days")) {
                props.onInputChangeHandler(key, newDate, undefined);
            } else {
                props.onInputChangeHandler(key, new Date(moment(props.poDetails.get("serviceDate"))), undefined);
            }
        }
    };


    const renderValidationPOOverlay = (fieldName, errorMessage, flag,errorMessageValue) => {
        let fieldId = fieldName.current !== undefined ?
            fieldName.current.input !== undefined ?
                fieldName.current.input.id :
                fieldName.current.id
            : undefined;

        return <div className=""> <Overlay
            show={flag && errorMessageValue.props !== undefined}
            target={fieldName.current}
            placement="right"

          >
            <Popover id={"popOver" + fieldId} className="create_po_form__error_message">
            <RawIntlProvider value={props.intl}>
                <FormattedMessage values={{poCreationError: errorMessageValue}} {...errorMessage} />
                </RawIntlProvider>
            </Popover>
        </Overlay></div>
    }

    const odometerValidationCheck = () => {

        props.odometerCheck(txtOdometer.current.value);
    }

        const messages = defineMessages({
            odometer_details_caption: {
                id: 'odometer_details_caption',
                description: 'Required updates',
                defaultMessage: 'Required updates'
            },
            odometer_details_odometer: {
                id: 'odometer_details_odometer',
                description: 'Odometer',
                defaultMessage: 'Odometer'
            },
            odometer_details_last_reading: {
                id: 'odometer_details_last_reading',
                description: 'Last reading',
                defaultMessage: 'Last reading'
            },
            car_wash_service_date_label: {
                id: 'car_wash_service_date_label',
                description: 'service date',
                defaultMessage: 'Service date'
            },
            car_wash_invoice_no_label: {
                id: 'car_wash_invoice_no_label',
                description: 'invoice no.',
                defaultMessage: 'Invoice No.'
            },
            odometer_details_optional: {
                id: 'odometer_details_optional',
                description: 'optional display',
                defaultMessage: ' (optional)'
            },
            odometer_details_select_one: {
                id: 'odometer_details_select_one',
                description: 'Select One',
                defaultMessage: 'Select One'
            },
            car_wash_repair_selection: {
                id: 'car_wash_repair_selection',
                description: 'Car Wash',
                defaultMessage: 'Car Wash'
            },
            detail_car_wash_repair_selection: {
                id: 'detail_car_wash_repair_selection',
                description: 'detail car wash',
                defaultMessage: 'Detail Car Wash'
            },
            car_wash_create_po: {
                id: 'car_wash_create_po',
                description: 'request payment',
                defaultMessage: 'Request Payment of '
            },
            odometer_details_unavailable: {
                id: 'odometer_details_unavailable',
                description: 'Unavailable',
                defaultMessage: 'Unavailable'
            },
            ododmeter_Validation_Message_1: {
                id: 'ododmeter_Validation_Message_1',
                description: 'Warning: The meter entered {newOdometer} differs from the estimated meter by:{odometerDifference}.',
                defaultMessage: 'Warning: The meter entered {newOdometer} differs from the estimated meter by:{odometerDifference}.'
            },
            ododmeter_Validation_Message_2: {
                id: 'ododmeter_Validation_Message_2',
                description: 'Warning: The meter entered exceeds the estimated meter by: {newOdometer}.',
                defaultMessage: 'Warning: The meter entered exceeds the estimated meter by: {newOdometer}.'
            },
            ododmeter_Validation_Message_3: {
                id: 'ododmeter_Validation_Message_3',
                description: 'Warning: The meter entered {newOdometer} differs from the expected meter {estimatedOdometer} for {currentDate} by:{estimatedOdometerDiff}.',
                defaultMessage: 'Warning: The meter entered {newOdometer} differs from the expected meter {estimatedOdometer} for {currentDate} by:{estimatedOdometerDiff}.'
            },
            ododmeter_Validation_Message_4: {
                id: 'ododmeter_Validation_Message_4',
                description: 'Warning: The meter entered {newOdometer} differs from the estimated meter by: {odometerDifference}.',
                defaultMessage: 'Warning: The meter entered {newOdometer} differs from the estimated meter by: {odometerDifference}.'
            },
            ododmeter_Validation_Message_5: {
                id: 'ododmeter_Validation_Message_5',
                description: 'Warning: This Unit is identified as EQUIPMENT. Odometer reading will be copied to Hour Meter reading based on client needs.',
                defaultMessage: 'Warning: This Unit is identified as EQUIPMENT. Odometer reading will be copied to Hour Meter reading based on client needs.'
            },
            hourmeter_Validation_Message: {
                id: 'hourmeter_Validation_Message',
                description: 'Warning: Hour meter cannot be less than last recorded value.',
                defaultMessage: 'Warning: Hour meter cannot be less than last recorded value.'
            },
            ododmeter_Validation_Message_6: {
                id: 'ododmeter_Validation_Message_6',
                description: 'Please enter Odometer value',
                defaultMessage: 'Please enter Odometer value'
            },
            shop_select: {
                id: 'shop_select',
                description: 'Odometer shop or not selection error message',
                defaultMessage: 'Please Select One!'
            },
            driver_validation_text: {
                id: 'driver_validation_text',
                description: 'validation warning for drivers who need to call Holman',
                defaultMessage: 'This purchase order requires driver validation. Advise driver to validate repairs with Holman prior to performing work.s'

            },
            total_info_label_car_wash: {
                id: 'total_info_label_car_wash',
                description: 'total',
                defaultMessage: 'Total'
            },
            tax_info_label_car_wash: {
                id: 'tax_info_label_car_wash',
                description: 'tax',
                defaultMessage: 'Tax'
            },tax_gst: {
                id: 'tax_gst',
                description: 'GST',
                defaultMessage: 'GST'
            },
            tax_pst: {
                id: 'tax_pst',
                description: 'PST',
                defaultMessage: 'PST'
            },
            tax_hst: {
                id: 'tax_hst',
                description: 'HST',
                defaultMessage: 'HST'
            },
            tax_qst: {
                id: 'tax_qst',
                description: 'QST',
                defaultMessage: 'QST'
            },
            po_creation_error: {
                id: 'po_creation_error',
                description: 'po_creation_error',
                defaultMessage: '{poCreationError}'
            },
            user_action_cancel_po: {
                id: 'user_action_cancel_po',
                description: 'Cancel PO',
                defaultMessage: 'Cancel PO'
            },

        });
        var vehicleDetails = props.vehicleDetails.toJSON();
        var applicationTaxType =props.applicableTaxTypes !== undefined ? props.applicableTaxTypes.map((tax)=> {return tax.toArray().toString().substring(0,3)}).toArray().toString() : "";

        return <div className="container-fluid create_po_form">
            <FormWrapper key='poCreationErrorMessage' id="poCreationErrorMessage" {...props} formErrors={props.errorMessage} />
            <div className="row create_po_form__caption">
                <div className="">
                    <FormattedMessage {...messages.odometer_details_caption} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="create_po_form__label">
                                <FormattedMessage {...messages.car_wash_service_date_label} />
                            </div>

                        </div>
                        <div className="col-md-3">
                            <InputCalendar 
                            initialValue={props.poDetails.get("serviceDate")}
                            modifiers={{ disabled: {
                                before: new Date(moment().subtract(29, "days")),
                                after:new Date(moment().subtract(0, "days")),
                            }}}
                            onDateChange={(e) => onDateChangeHandler(PO_FIELD_TYPE.SERVICE_DATE, e)}
                            inputProps={{className:'create_po_day_picker', tabIndex:1 }}
                            locale={props.userLocale}
                            ref={dtPickerServiceDate}
                            id={'dtPickerServiceDate'}
                            />

                            {
                            renderValidationPOOverlay(dtPickerServiceDate,messages.po_creation_error, props.serviceDateError !== undefined,props.serviceDateError)
                            }

                        </div>
                      </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="create_po_form__label">
                                <FormattedMessage {...messages.car_wash_invoice_no_label} />
                            </div>

                        </div>
                        <div className="col-md-3">

                            <input className="create_po_form__input-box" type="text"
                                   ref={txtInvoiceNo}
                                   onInput={resetMaxValue.bind(this, 25)}
                                   onChange={props.onInputChangeHandler.bind(this,PO_FIELD_TYPE.INVOICE_NO,null)}
                                   cols="6"
                                   maxLength="25"
                                   size="6"
                                   tabIndex={2}
                                   id='txtInvoiceNo'
                            />
                            {renderValidationPOOverlay(txtInvoiceNo,messages.po_creation_error, props.invoiceNumberError !== undefined,props.invoiceNumberError)}

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="create_po_form__label">
                                <FormattedMessage {...messages.odometer_details_odometer} />
                            </div>
                        </div>
                        <div className="col-md-3">

                            <input className="create_po_form__input-box" type="number"
                                   ref={txtOdometer}
                                   id='txtOdometer'
                                   cols="6"
                                   size="6"
                                   tabIndex={3}
                                   onBlur={props.poDetails.get("odometer") !== 0 ? odometerValidationCheck.bind(this) : undefined}
                                   onChange={props.onInputChangeHandler.bind(this,PO_FIELD_TYPE.ODOMETER,null)}
                                   defaultValue={props.poDetails.get("odometer") !== 0 ? props.poDetails.get("odometer") : ""}
                            />
                            {renderValidationPOOverlay(txtOdometer,messages.po_creation_error, props.odometerError !== undefined,props.odometerError)}

                        </div>
                    </div>
                    <div className="row">
                    <div className=" col-md-4 create_po_form__label_optional">
                        <FormattedMessage {...messages.odometer_details_optional} />
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 create_po_form_unedit__label">
                            <FormattedMessage {...messages.odometer_details_last_reading} />
                        </div>
                        <div className="col-md-3 create_po_form_unedit__val">
                                {vehicleDetails.lastOdometerReading == undefined || vehicleDetails.lastOdometerReading == '' ?
                                    <FormattedMessage {...messages.odometer_details_unavailable} /> : vehicleDetails.lastOdometerReading}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 create_po_form_select">
                            <FormattedMessage {...messages.odometer_details_select_one} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="create_po_form__label">
                                <div className="create_po_form__radio"
                                     onClick={isCarWashRepairChangeHandler.bind(this, true)}

                                >
                                    <img src={!props.carWashServiceData || props.carWashServiceData === undefined
                                        ? "/assets/VMS_unchecked.png"
                                        : "/assets/VMS_checked.png"}
                                    />
                                    {' '} <FormattedMessage {...messages.car_wash_repair_selection} />

                                </div>
                            </div>

                        </div>
                        <div className="col-md-3">

                            <DynamicNumber
                                className={classNames('tax-control',"create_po_form__tax",{"create_po_form-input disabled" : !props.carWashServiceData  })}
                                ref={txtCarWashRepairCost}
                                id='txtCarWashRepairCost'
                                tabIndex={!props.carWashServiceData ? -1 : 4}
                                onChange={props.onInputChangeHandler.bind(this,PO_FIELD_TYPE.CAR_WASH_COST,null)}
                                separator={'.'}
                                integer={7}
                                fraction={2}
                                positive={true}
                                negative={false}
                                thousand={false}
                                value={props.poDetails.get("carWashCost") !== undefined  ? props.poDetails.get("carWashCost") : 0}
                            />
                            {renderValidationPOOverlay(txtCarWashRepairCost,messages.po_creation_error, props.carWashCostError !== undefined,props.carWashCostError)}

                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-4">
                            <div className="create_po_form__label">
                                <div className="create_po_form__radio"
                                     onClick={isCarWashRepairChangeHandler.bind(this, false)}

                                >
                                    <img src={props.carWashServiceData || props.carWashServiceData === undefined
                                        ? "/assets/VMS_unchecked.png"
                                        : "/assets/VMS_checked.png"}
                                    />
                                    {' '} <FormattedMessage {...messages.detail_car_wash_repair_selection} />
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3">

                            <DynamicNumber
                                className={classNames('tax-control',"create_po_form__tax",{"create_po_form-input disabled" : props.carWashServiceData })}
                                ref={txtDetailCarWashRepairCost}
                                id='txtDetailCarWashRepairCost'
                                tabIndex={props.carWashServiceData ? -1 : 5}
                                onChange={props.onInputChangeHandler.bind(this,PO_FIELD_TYPE.DETAIL_CAR_WASH_COST,null)}
                                separator={'.'}
                                integer={7}
                                fraction={2}
                                positive={true}
                                negative={false}
                                thousand={false}
                                value={props.poDetails.get("detailCarWashCost") !== undefined ? props.poDetails.get("detailCarWashCost") : 0}
                            />
                            {renderValidationPOOverlay(txtDetailCarWashRepairCost,messages.po_creation_error, props.detailCarWashCostError !== undefined,props.detailCarWashCostError)}

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 ">
                            <div className="create_po_form__label">
                                <FormattedMessage {...messages.tax_info_label_car_wash} />
                            </div>
                        </div>
                        {props.country === "USA" ?
                            <div className="col-md-3">
                                <DynamicNumber
                                    className={classNames("create_po_form__tax", 'tax-control')}
                                    ref={txtCarWashTax}
                                    id='txtCarWashTax'
                                    data-taxType={LINE_ITEM_TYPE.TAX}
                                    tabIndex={6}
                                    separator={'.'}
                                    integer={7}
                                    fraction={2}
                                    positive={true}
                                    negative={false}
                                    thousand={false}
                                    value={props.poDetails.get("taxAmount") !== undefined ? props.poDetails.get("taxAmount") : 0}
                                    onChange={props.onInputChangeHandler.bind(this,PO_FIELD_TYPE.TAX_AMOUNT,null)}
                                />
                                {renderValidationPOOverlay(txtCarWashTax,messages.po_creation_error,props.taxAmountError !== undefined,props.taxAmountError)}


                            </div>

                            : <div>
                                <div className="create_po_form__canadian-control">
                                    <div
                                       >
                                        <div className="create_po_form__tax_field-header">
                                            <FormattedMessage {...messages.tax_hst}/>
                                            {' '}
                                        </div>


                                        <DynamicNumber
                                            className={classNames("create_po_form__tax_canada", 'tax-control')}
                                            data-taxType="HST" ref={txtHST}
                                            id='txtHST'
                                            tabIndex={6}
                                             separator={'.'}
                                            integer={5}
                                            fraction={2}
                                            positive={true}
                                            negative={false}
                                            thousand={false}
                                            value={props.taxCanada.get("HST") !== undefined ? props.taxCanada.get("HST") : 0}
                                            onChange={props.onInputChangeHandler.bind(this,PO_FIELD_TYPE.TAX_AMOUNT,null)}
                                            disabled={!applicationTaxType.includes("HST")}
                                        />

                                    </div>

                                    <div
                                       >
                                        <div className="create_po_form__tax_field-header">
                                            <FormattedMessage {...messages.tax_gst} />
                                            {' '}
                                        </div>


                                        <DynamicNumber
                                            className={classNames("create_po_form__tax_canada", 'tax-control')}
                                            data-taxType="GST" ref={txtGST}
                                            id='txtGST'
                                            tabIndex={7}
                                            onChange={props.onInputChangeHandler.bind(this,PO_FIELD_TYPE.TAX_AMOUNT,null)}
                                            separator={'.'}
                                            integer={5}
                                            fraction={2}
                                            positive={true}
                                            negative={false}
                                            thousand={false}
                                            value={props.taxCanada.get("GST") !== undefined ? props.taxCanada.get("GST") : 0 }
                                            disabled={!applicationTaxType.includes("GST")}
                                        />
                                    </div>
                                </div>

                                <div className="create_po_form__canadian-control">
                                    <div
                                       >
                                        <div className="create_po_form__tax_field-header">
                                            <FormattedMessage {...messages.tax_pst}/>
                                            {' '}
                                        </div>


                                        <DynamicNumber
                                            className={classNames("create_po_form__tax_canada", 'tax-control')}
                                            data-taxType="PST" ref={txtPST}
                                            id='txtPST'
                                            tabIndex={8}
                                            onChange={props.onInputChangeHandler.bind(this,PO_FIELD_TYPE.TAX_AMOUNT,null)}
                                            separator={'.'}
                                            integer={5}
                                            fraction={2}
                                            positive={true}
                                            negative={false}
                                            thousand={false}
                                            value={props.taxCanada.get("PST") !== undefined ? props.taxCanada.get("PST"): 0 }
                                            disabled={!applicationTaxType.includes("PST")}
                                        />

                                    </div>

                                    <div>
                                        <div className="create_po_form__tax_field-header">
                                            <FormattedMessage {...messages.tax_qst}/>
                                            {' '}
                                        </div>



                                        <DynamicNumber
                                            className={classNames("create_po_form__tax_canada", 'tax-control')}
                                            data-taxType="QST" ref={txtQST}
                                            id='txtQST'
                                            tabIndex={9}
                                            onChange={props.onInputChangeHandler.bind(this,PO_FIELD_TYPE.TAX_AMOUNT,null)}
                                            separator={'.'}
                                            integer={5}
                                            fraction={2}
                                            positive={true}
                                            negative={false}
                                            thousand={false}
                                            value={props.taxCanada.get("QST") !== undefined ? props.taxCanada.get("QST") : 0 }
                                            disabled={!applicationTaxType.includes("QST")}
                                        />

                                    </div>
                                    {renderValidationPOOverlay(txtQST,messages.po_creation_error, props.canadianTaxError !== undefined,props.canadianTaxError)}

                                </div>
                            </div>


                        }
                    </div>
                    <div className="row">
                        <div className="col-md-4 ">
                            <div className="create_po_form__label">
                                <FormattedMessage {...messages.total_info_label_car_wash} />
                            </div>
                        </div>
                        <div className="col-md-3">

                            <DynamicNumber
                                className={classNames("create_po_form__tax", 'tax-control')}
                                disabled
                                separator={'.'}
                                integer={7}
                                fraction={2}
                                positive={true}
                                negative={false}
                                thousand={false}
                                value={props.carWashTotal !== undefined ? Number(props.carWashTotal).toFixed(2) : 0}
                            />
                        </div>
                    </div>
                    <div className="row create_po_form__save">
                        <div className='create_po_form__cancel col'>
                            <a onClick={props.onCancelClick}>
                                <img src="/assets/VMS_28button_delete_blue.png"/>
                                {' '}{<FormattedMessage {...messages.user_action_cancel_po}/>}
                            </a>
                        </div>
                        <div className='create_po_form__request col'>
                            <a onClick={onCreatePOValidation.bind(this)}>
                                <FormattedMessage {...messages.car_wash_create_po} />
                                <IntlProvider locale="en">
                                    <FormattedNumber value={Number(props.carWashTotal).toFixed(2)} style="currency"
                                        currency={props.selectedCurrency} />
                                </IntlProvider>
                                {' '}<img src="/assets/VMS_33button_go_bblue.png" />

                            </a>
                        </div>
                        <div className="inline-block">
                            {props.isCreatingPO ?
                                <Spinner spinnerType="dark-blue"/>
                                : undefined
                            }
                        </div>
                    </div>
                </div>
            </div>


        </div>;
}


export default injectIntl(CreatePOForm);

